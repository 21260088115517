import { takeEvery, call, put } from 'redux-saga/effects';
import {
    errorMessageAction,
    setExecutionHistoryState,
    updateApiTestDataState,
    updateApiEnvDataState,
    updateApiScheduleDataState,
    updateApiTagDataState,
    updateApiPlanDataState,
    updatePendingExecutionsState,
    updateTestDataInState,
    updateScheduleDataInState,
    updateTagDataInState,
    updatePlanDataInState,
    updateWaitlistState,
    waitAction,
    waitDoneAction,
    updateApiAllPlanReportsDataState,
    updateApiAllTestPlanReportsDataState,
    updateApiTestPlanReportDataState,
    updateApiPlanReportDataState,
    updateDefaultSettingsDataInState
} from '../../sync/actionCreators';
import {
    CREATE_TEST_API,
    RUN_TEST_API,
    GET_ALL_TESTS_API,
    GET_JOIN_WAITLIST_API,
    GET_PENDING_EXECUTIONS_API,
    GET_RUN_HISTORY_API,
    POST_JOIN_WAITLIST_API,
    SEND_FEEDBACK_API,
    UPDATE_TEST_API,
    DELETE_TEST_API,
    GET_ALL_ENVS_API,
    UPDATE_ENV_API,
    CREATE_ENV_API,
    DELETE_ENV_API,
    GET_ALL_SCHEDULES_API,
    UPDATE_SCHEDULE_API,
    CREATE_SCHEDULE_API,
    DELETE_SCHEDULE_API,
    GET_ALL_TAGS_API,
    UPDATE_TAG_API,
    CREATE_TAG_API,
    DELETE_TAG_API,
    GET_ALL_PLANS_API,
    UPDATE_PLAN_API,
    CREATE_PLAN_API,
    DELETE_PLAN_API,
    GET_ALL_TEST_PLAN_REPORTS_API,
    GET_ALL_PLAN_REPORTS_API,
    GET_TEST_PLAN_REPORT_API,
    GET_PLAN_REPORT_API,
    RUN_PLAN_API,
    ABORT_RUN_PLAN_API,
    GET_DEFAULT_SETTINGS_API,
    UPDATE_DEFAULT_SETTINGS_API
} from '../actionConstants';
import { getAllPlanReportsAction, getAllTestPlanReportsAction, getAllTestsAction } from '../actionCreators';
import {
    CreateTestAction,
    RunTestAction,
    GetAllTestsDataAction,
    GetJoinWaitlistStatusAction,
    GetRunHistoryAction,
    JoinWaitlistAction,
    SendFeedbackAction,
    UpdateTestAction,
    DeleteTestAction,
    CreateEnvAction,
    GetAllEnvsDataAction,
    UpdateEnvAction,
    DeleteEnvAction,
    CreateScheduleAction,
    GetAllSchedulesDataAction,
    UpdateScheduleAction,
    DeleteScheduleAction,
    CreateTagAction,
    GetAllTagsDataAction,
    UpdateTagAction,
    DeleteTagAction,
    CreatePlanAction,
    GetAllPlansDataAction,
    UpdatePlanAction,
    DeletePlanAction,
    GetTestPlanReportDataAction,
    GetPlanReportDataAction,
    GetAllTestPlanReportsDataAction,
    GetAllPlanReportsDataAction,
    RunPlanAction,
    AbortRunPlanAction,
    GetDefaultSettingsDataAction,
    UpdateDefaultSettingsDataAction
} from '../actionTypes';
import {
    getAllTestsAPI,
    executeTestByIdAPI,
    updateTestByIdAPI,
    createTestAPI,
    getExecutionHistoryAPI,
    getPendingExecutionsAPI,
    sendFeedbackAPI,
    joinWaitlistAPI,
    getJoinWaitlistAPI,
    deleteTestAPI,
    getAllEnvsAPI,
    updateEnvByIdAPI,
    createEnvAPI,
    deleteEnvAPI,
    getAllSchedulesAPI,
    createScheduleAPI,
    updateScheduleByIdAPI,
    deleteScheduleAPI,
    getAllTagsAPI,
    createTagAPI,
    updateTagByIdAPI,
    deleteTagAPI,
    getAllPlansAPI,
    createPlanAPI,
    updatePlanByIdAPI,
    deletePlanAPI,
    getAllTestPlanReportsAPI,
    getTestPlanReportByIdAPI,
    getAllPlanReportsAPI,
    getPlanReportByIdAPI,
    executePlanByIdAPI,
    abortPlanRunAPI,
    getDefaultSettingsAPI,
    updateDefaultSettingsAPI
} from './api';
import { ExecutionHistoryAPIData, PendingExecutionsAPIData } from './APITypes';

export default function* rootSaga() {
    // yield all([getTodoListSaga(), updateTodoSaga(), addTodoSaga()]);
    yield takeEvery(GET_ALL_TESTS_API, getAllTestsAPISaga);
    // yield takeEvery(GET_TEST_STEPS_API, getTestStepsAPISaga);
    yield takeEvery(RUN_TEST_API, executeTestByIdAPISaga);
    yield takeEvery(RUN_PLAN_API, executePlanByIdAPISaga);
    yield takeEvery(ABORT_RUN_PLAN_API, abortPlanByIdAPISaga);
    yield takeEvery(CREATE_TEST_API, createTestSaga);
    yield takeEvery(UPDATE_TEST_API, updateTestByIdSaga);
    yield takeEvery(DELETE_TEST_API, deleteTestAPISaga);
    yield takeEvery(CREATE_ENV_API, createEnvSaga);
    yield takeEvery(UPDATE_ENV_API, updateEnvByIdSaga);
    yield takeEvery(DELETE_ENV_API, deleteEnvAPISaga);
    yield takeEvery(GET_ALL_ENVS_API, getAllEnvsAPISaga);
    yield takeEvery(CREATE_SCHEDULE_API, createScheduleSaga);
    yield takeEvery(UPDATE_SCHEDULE_API, updateScheduleByIdSaga);
    yield takeEvery(DELETE_SCHEDULE_API, deleteScheduleAPISaga);
    yield takeEvery(GET_ALL_SCHEDULES_API, getAllSchedulesAPISaga);
    yield takeEvery(CREATE_TAG_API, createTagSaga);
    yield takeEvery(UPDATE_TAG_API, updateTagByIdSaga);
    yield takeEvery(DELETE_TAG_API, deleteTagAPISaga);
    yield takeEvery(GET_ALL_TAGS_API, getAllTagsAPISaga);
    yield takeEvery(CREATE_PLAN_API, createPlanSaga);
    yield takeEvery(UPDATE_PLAN_API, updatePlanByIdSaga);
    yield takeEvery(DELETE_PLAN_API, deletePlanAPISaga);
    yield takeEvery(GET_ALL_PLANS_API, getAllPlansAPISaga);
    yield takeEvery(GET_RUN_HISTORY_API, getExecutionHistorySaga);
    yield takeEvery(GET_PENDING_EXECUTIONS_API, getPendingExecutionsSaga);
    yield takeEvery(SEND_FEEDBACK_API, sendFeedbackApiSaga);
    yield takeEvery(POST_JOIN_WAITLIST_API, joinWaitlistAPISaga);
    yield takeEvery(GET_JOIN_WAITLIST_API, getJoinWaitlistAPISaga);
    yield takeEvery(GET_ALL_TEST_PLAN_REPORTS_API, getAllTestPlanReportsAPISaga);
    yield takeEvery(GET_ALL_PLAN_REPORTS_API, getAllPlanReportsAPISaga);
    yield takeEvery(GET_TEST_PLAN_REPORT_API, getTestPlanReportAPISaga);
    yield takeEvery(GET_PLAN_REPORT_API, getPlanReportAPISaga);
    yield takeEvery(GET_DEFAULT_SETTINGS_API, getDefaultSettingsAPISaga);
    yield takeEvery(UPDATE_DEFAULT_SETTINGS_API, updateDefaultSettingsAPISaga);
}

function* handleError(error: any) {
    if (error.response && error.response.data) {
        yield put(errorMessageAction(error.response.data.error));
    } else {
        console.error(error);
        yield put(errorMessageAction('Error occured.'));
    }
}

// ------------------------- Test Sagas ------------------------

function* createTestSaga(action: CreateTestAction) {
    const { workspaceId, testData } = action.payload;

    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(createTestAPI, workspaceId, testData);
            const testId = newPayload.id;
            yield put(updateTestDataInState(workspaceId, testId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updateTestByIdSaga(action: UpdateTestAction) {
    const { workspaceId, testId, testData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updateTestByIdAPI, workspaceId, testId, testData);
            yield put(updateTestDataInState(workspaceId, testId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* deleteTestAPISaga(action: DeleteTestAction) {
    const { workspaceId, testId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            yield call(deleteTestAPI, workspaceId, testId);
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());

        // ask UI to reload data
        yield put(getAllTestsAction(workspaceId));
        // yield put(getExecutionHistoryAction(workspaceId));
        // yield put(getPendingExecutionsAction(workspaceId));
        yield put(getAllTestPlanReportsAction(workspaceId));
        yield put(getAllPlanReportsAction(workspaceId));
    }
}

function* executeTestByIdAPISaga(action: RunTestAction) {
    const { workspaceId, testId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(executeTestByIdAPI, workspaceId, { testId });
            yield put(updatePendingExecutionsState(newPayload, testId, workspaceId, 'test'));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* executePlanByIdAPISaga(action: RunPlanAction) {
    const { workspaceId, planId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(executePlanByIdAPI, workspaceId, { planId });
            yield put(updatePendingExecutionsState(newPayload, planId, workspaceId, 'plan'));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* abortPlanByIdAPISaga(action: AbortRunPlanAction) {
    const { workspaceId, planReportId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(abortPlanRunAPI, workspaceId, planReportId);
            // yield put(updateAbortPlanState(newPayload, planReportId, workspaceId));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllTestsAPISaga(action: GetAllTestsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllTestsAPI, workspaceId);
            yield put(updateApiTestDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getExecutionHistorySaga(action: GetRunHistoryAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload: ExecutionHistoryAPIData = yield call(getExecutionHistoryAPI, workspaceId);
            const { executionListByTest, executionList } = newPayload;
            yield put(setExecutionHistoryState(executionListByTest, executionList));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getPendingExecutionsSaga(action: GetRunHistoryAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload: PendingExecutionsAPIData = yield call(getPendingExecutionsAPI, workspaceId);
            yield put(updatePendingExecutionsState(newPayload, null, workspaceId));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Environment Sagas ------------------------

function* createEnvSaga(action: CreateEnvAction) {
    const { workspaceId, envData } = action.payload;

    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(createEnvAPI, workspaceId, envData);
            const envId = newPayload.id;
            yield put(updateTestDataInState(workspaceId, envId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updateEnvByIdSaga(action: UpdateEnvAction) {
    const { workspaceId, envId, envData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updateEnvByIdAPI, workspaceId, envId, envData);
            yield put(updateTestDataInState(workspaceId, envId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* deleteEnvAPISaga(action: DeleteEnvAction) {
    const { workspaceId, envId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            yield call(deleteEnvAPI, workspaceId, envId);
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllEnvsAPISaga(action: GetAllEnvsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllEnvsAPI, workspaceId);
            yield put(updateApiEnvDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Schedule Sagas ------------------------

function* createScheduleSaga(action: CreateScheduleAction) {
    const { workspaceId, scheduleData } = action.payload;

    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(createScheduleAPI, workspaceId, scheduleData);
            const scheduleId = newPayload.id;
            yield put(updateScheduleDataInState(workspaceId, scheduleId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updateScheduleByIdSaga(action: UpdateScheduleAction) {
    const { workspaceId, scheduleId, scheduleData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updateScheduleByIdAPI, workspaceId, scheduleId, scheduleData);
            yield put(updateScheduleDataInState(workspaceId, scheduleId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* deleteScheduleAPISaga(action: DeleteScheduleAction) {
    const { workspaceId, scheduleId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            yield call(deleteScheduleAPI, workspaceId, scheduleId);
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllSchedulesAPISaga(action: GetAllSchedulesDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllSchedulesAPI, workspaceId);
            yield put(updateApiScheduleDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Tag Sagas ------------------------

function* createTagSaga(action: CreateTagAction) {
    const { workspaceId, tagData } = action.payload;

    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(createTagAPI, workspaceId, tagData);
            const tagId = newPayload.id;
            yield put(updateTagDataInState(workspaceId, tagId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updateTagByIdSaga(action: UpdateTagAction) {
    const { workspaceId, tagId, tagData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updateTagByIdAPI, workspaceId, tagId, tagData);
            yield put(updateTagDataInState(workspaceId, tagId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* deleteTagAPISaga(action: DeleteTagAction) {
    const { workspaceId, tagId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            yield call(deleteTagAPI, workspaceId, tagId);
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllTagsAPISaga(action: GetAllTagsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllTagsAPI, workspaceId);
            yield put(updateApiTagDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Plan Sagas ------------------------

function* createPlanSaga(action: CreatePlanAction) {
    const { workspaceId, planData } = action.payload;

    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(createPlanAPI, workspaceId, planData);
            const planId = newPayload.id;
            yield put(updatePlanDataInState(workspaceId, planId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updatePlanByIdSaga(action: UpdatePlanAction) {
    const { workspaceId, planId, planData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updatePlanByIdAPI, workspaceId, planId, planData);
            yield put(updatePlanDataInState(workspaceId, planId, newPayload));
            const allSchedulesPayload = yield call(getAllSchedulesAPI, workspaceId);
            yield put(updateApiScheduleDataState(allSchedulesPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* deletePlanAPISaga(action: DeletePlanAction) {
    const { workspaceId, planId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            yield call(deletePlanAPI, workspaceId, planId);
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllPlansAPISaga(action: GetAllPlansDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllPlansAPI, workspaceId);
            yield put(updateApiPlanDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Plan Report Sagas ------------------------

function* getAllPlanReportsAPISaga(action: GetAllPlanReportsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllPlanReportsAPI, workspaceId);
            yield put(updateApiAllPlanReportsDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getPlanReportAPISaga(action: GetPlanReportDataAction) {
    const { workspaceId, planExecutionId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getPlanReportByIdAPI, workspaceId, planExecutionId);
            yield put(updateApiPlanReportDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getAllTestPlanReportsAPISaga(action: GetAllTestPlanReportsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getAllTestPlanReportsAPI, workspaceId);
            yield put(updateApiAllTestPlanReportsDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getTestPlanReportAPISaga(action: GetTestPlanReportDataAction) {
    const { workspaceId, planExecutionId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getTestPlanReportByIdAPI, workspaceId, planExecutionId);
            yield put(updateApiTestPlanReportDataState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Settings Sagas ------------------------

function* getDefaultSettingsAPISaga(action: GetDefaultSettingsDataAction) {
    const { workspaceId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getDefaultSettingsAPI, workspaceId);
            yield put(updateDefaultSettingsDataInState(workspaceId, newPayload));
        } catch (e) {
            handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* updateDefaultSettingsAPISaga(action: UpdateDefaultSettingsDataAction) {
    const { workspaceId, settingsData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(updateDefaultSettingsAPI, workspaceId, settingsData);
            yield put(updateDefaultSettingsDataInState(workspaceId, newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// ------------------------- Other Sagas ------------------------

function* sendFeedbackApiSaga(action: SendFeedbackAction) {
    const { workspaceId, feedbackData } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            // we don't care about the response
            yield call(sendFeedbackAPI, workspaceId, feedbackData);
            //yield put(updatePendingExecutionsState(newPayload, null, workspaceId));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* joinWaitlistAPISaga(action: JoinWaitlistAction) {
    const { workspaceId, userId, name } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(joinWaitlistAPI, workspaceId, { userId, name });
            yield put(updateWaitlistState(newPayload));
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

function* getJoinWaitlistAPISaga(action: GetJoinWaitlistStatusAction) {
    const { workspaceId, userId } = action.payload;
    if (workspaceId) {
        yield put(waitAction());
        try {
            const newPayload = yield call(getJoinWaitlistAPI, workspaceId, userId);
            if (!newPayload.error) {
                yield put(updateWaitlistState(newPayload));
            }
        } catch (e) {
            yield handleError(e);
        }
        yield put(waitDoneAction());
    }
}

// function* getTestStepsAPISaga(action: GetTestDataAction) {
//     const { workspaceId } = action.payload;
//     const { testId } = action.payload;
//     if (workspaceId) {
//         try {
//             const newPayload = yield call(
//                 getTestStepsByIdAPI,
//                 workspaceId,
//                 testId
//             );

//             yield put(
//                 updateApiTestStepState(newPayload.id, newPayload.stepList)
//             );
//         } catch (e) {
//             yield handleError(e);
//         }
//     }
// }
