import axios from 'axios';
import {
    TestType,
    EnvType,
    ScheduleType,
    TagType,
    PlanType,
    WorkspaceSettingsType,
    GlobalVariablesType
} from '@shaztra/types/DBTypes';
import { ExecutionHistoryAPIData, PendingExecutionsAPIData } from './APITypes';
import { trackHeapEvent } from 'src/ui/app/analytics/heap';
// export const API_URL = process.env.NEXT_PUBLIC_API_ENDPOINT; // === 'production' ? 'https://shaztra.com/api' : 'http://localhost:3000/api';
axios.defaults.baseURL = '/api';
axios.defaults.headers.common['Content-Type'] = 'application/json';
// Add a request interceptor
axios.interceptors.request.use(
    function (config) {
        if (config) {
            const url = config.url;
            const method = config.method;
            if (method !== 'get') {
                trackHeapEvent(`API`, { url, method });
            }
        }
        // Do something before request is sent
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    }
);

export const fetcher = (url) => axios.get(url).then((res) => res.data);

export async function getAllTestsAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/tests`);
    return response.data;
}

export async function getTestByIdAPI(workspaceId: string, testId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/tests/${testId}`);
    return response.data;
}

export async function getTestStepsByIdAPI(workspaceId: string, testId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/tests/${testId}/steps`);
    return response.data;
}

export async function executeTestByIdAPI(workspaceId: string, apiPayload: any) {
    // it is just a POST without any data params
    const response = await axios.post(`/workspaces/${workspaceId}/run/test`, apiPayload);
    return response.data;
}

export async function executePlanByIdAPI(workspaceId: string, apiPayload: any) {
    // it is just a POST without any data params
    const response = await axios.post(`/workspaces/${workspaceId}/run/plan`, apiPayload);
    return response.data;
}

function cleanSteps(testData: TestType) {
    const apiPayload = { ...testData };
    // clean the steps
    if (testData.steps) {
        // remove any empty lines
        const filteredSteps = testData.steps.filter((val) => val.trim().length > 0);
        apiPayload.steps = filteredSteps;
    }
    return apiPayload;
}

export async function updateTestByIdAPI(workspaceId: string, testId: string, testData: TestType) {
    const apiPayload = cleanSteps(testData);
    const response = await axios.put(`/workspaces/${workspaceId}/tests/${testId}`, apiPayload);
    return response.data;
}

export async function createTestAPI(workspaceId: string, testData: TestType) {
    const apiPayload = cleanSteps(testData);
    const response = await axios.post(`/workspaces/${workspaceId}/tests`, apiPayload);
    return response.data;
}

export async function getExecutionHistoryAPI(workspaceId: string): Promise<ExecutionHistoryAPIData> {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/executions
    const response = await axios.post(`/workspaces/${workspaceId}/executions`, {
        workspaceId
    });
    return response.data as ExecutionHistoryAPIData;
}

export async function getPendingExecutionsAPI(workspaceId: string): Promise<PendingExecutionsAPIData> {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/executions
    const response = await axios.post(`/workspaces/${workspaceId}/executions/pending`, {
        workspaceId
    });
    return response.data as PendingExecutionsAPIData;
}

export async function sendFeedbackAPI(workspaceId: string, feedbackData: Record<string, any>): Promise<any> {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/executions
    const response = await axios.post(`/workspaces/${workspaceId}/executions/feedback`, {
        workspaceId,
        ...feedbackData
    });
    return response.data as PendingExecutionsAPIData;
}

export async function joinWaitlistAPI(workspaceId: string, apiPayload: any) {
    const response = await axios.post(`/workspaces/${workspaceId}/joinWaitlist`, apiPayload);
    return response.data;
}

export async function getJoinWaitlistAPI(workspaceId: string, userId: string) {
    const response = await axios.get(`/workspaces/${workspaceId}/joinWaitlist/${userId}`);
    return response.data;
}

export async function deleteTestAPI(workspaceId: string, testId: string) {
    const response = await axios.delete(`/workspaces/${workspaceId}/tests/${testId}`);
    return response.data;
}

// ------------ Env APIs ---------------------------------

export async function getAllEnvsAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/envs`);
    return response.data;
}

export async function getEnvByIdAPI(workspaceId: string, envId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/envs/${envId}`);
    return response.data;
}
export async function updateEnvByIdAPI(workspaceId: string, envId: string, envData: EnvType) {
    const response = await axios.put(`/workspaces/${workspaceId}/envs/${envId}`, envData);
    return response.data;
}

export async function createEnvAPI(workspaceId: string, envData: EnvType) {
    const response = await axios.post(`/workspaces/${workspaceId}/envs`, envData);
    return response.data;
}

export async function deleteEnvAPI(workspaceId: string, envId: string) {
    const response = await axios.delete(`/workspaces/${workspaceId}/envs/${envId}`);
    return response.data;
}

// ------------ Schedule APIs ---------------------------------

export async function getAllSchedulesAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/schedules`);
    return response.data;
}

export async function getScheduleByIdAPI(workspaceId: string, scheduleId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/schedules/${scheduleId}`);
    return response.data;
}
export async function updateScheduleByIdAPI(workspaceId: string, scheduleId: string, scheduleData: ScheduleType) {
    const response = await axios.put(`/workspaces/${workspaceId}/schedules/${scheduleId}`, scheduleData);
    return response.data;
}

export async function createScheduleAPI(workspaceId: string, scheduleData: ScheduleType) {
    const response = await axios.post(`/workspaces/${workspaceId}/schedules`, scheduleData);
    return response.data;
}

export async function deleteScheduleAPI(workspaceId: string, scheduleId: string) {
    const response = await axios.delete(`/workspaces/${workspaceId}/schedules/${scheduleId}`);
    return response.data;
}

// ------------ Tag APIs ---------------------------------

export async function getAllTagsAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/tags`);
    return response.data;
}

export async function getTagByIdAPI(workspaceId: string, tagId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/tags/${tagId}`);
    return response.data;
}
export async function updateTagByIdAPI(workspaceId: string, tagId: string, tagData: TagType) {
    const response = await axios.put(`/workspaces/${workspaceId}/tags/${tagId}`, tagData);
    return response.data;
}

export async function createTagAPI(workspaceId: string, tagData: TagType) {
    const response = await axios.post(`/workspaces/${workspaceId}/tags`, tagData);
    return response.data;
}

export async function deleteTagAPI(workspaceId: string, tagId: string) {
    const response = await axios.delete(`/workspaces/${workspaceId}/tags/${tagId}`);
    return response.data;
}

// ------------ Plan APIs ---------------------------------

export async function getAllPlansAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/plans`);
    return response.data;
}

export async function getPlanByIdAPI(workspaceId: string, planId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/plans/${planId}`);
    return response.data;
}
export async function updatePlanByIdAPI(workspaceId: string, planId: string, planData: PlanType) {
    const response = await axios.put(`/workspaces/${workspaceId}/plans/${planId}`, planData);
    return response.data;
}

export async function createPlanAPI(workspaceId: string, planData: PlanType) {
    const response = await axios.post(`/workspaces/${workspaceId}/plans`, planData);
    return response.data;
}

export async function deletePlanAPI(workspaceId: string, planId: string) {
    const response = await axios.delete(`/workspaces/${workspaceId}/plans/${planId}`);
    return response.data;
}

// ------------ Plan Report APIs ---------------------------------

export async function getAllTestPlanReportsAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/reports/tests`);
    return response.data;
}

export async function getTestPlanReportByIdAPI(workspaceId: string, planExecutionId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/plans/reports/tests/${planExecutionId}`);
    return response.data;
}

export async function getAllPlanReportsAPI(workspaceId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests
    const response = await axios.get(`/workspaces/${workspaceId}/reports/plans`);
    return response.data;
}

export async function getPlanReportByIdAPI(workspaceId: string, planExecutionId: string) {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/tests/W08YcDm2KG5FgQCjGKOR
    const response = await axios.get(`/workspaces/${workspaceId}/plans/reports/plans/${planExecutionId}`);
    return response.data;
}

export async function abortPlanRunAPI(workspaceId: string, planReportId: string): Promise<any> {
    //localhost:3000/api/workspaces/bstATn4ITubVa8G8fgrX/executions
    const response = await axios.post(`/workspaces/${workspaceId}/reports/plans/${planReportId}`, {
        status: 'abort'
    });
    return response.data;
}

// ------------ Settings APIs ---------------------------------

export async function getDefaultSettingsAPI(workspaceId: string) {
    const response = await axios.get(`/workspaces/${workspaceId}/settings/default`);
    return response.data;
}

export async function updateDefaultSettingsAPI(
    workspaceId: string,
    settingsData: WorkspaceSettingsType & { name?: string }
) {
    const response = await axios.put(`/v0/${workspaceId}/workspace/settings`, settingsData);
    return response.data;
}

// ------------------ All Workspace Users APIs ----------------------

export async function getAllWorkspaceUsersAPI(workspaceId: string) {
    const response = await axios.get(`/workspaces/${workspaceId}/users`);
    return response.data;
}

export async function getWorkspaceUserByIdAPI(workspaceId: string, userId: string) {
    const response = await axios.get(`/workspaces/${workspaceId}/users/${userId}`);
    return response.data;
}

// ------------------ All Global Variables APIs ----------------------
export async function getGlobalVariablesAPI(workspaceId: string) {
    const response = await axios.get(`/workspaces/${workspaceId}/globalVariables`);
    return response.data;
}

export async function updateGlobalVariablesAPI(workspaceId: string, variableDoc: GlobalVariablesType) {
    const response = await axios.put(`/workspaces/${workspaceId}/globalVariables`, variableDoc);
    return response.data;
}

// ------------------ Files APIs ----------------------
export async function uploadFiles(workspaceId: string, formData: FormData) {
    const response = await axios.post(`/workspaces/${workspaceId}/files`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
    });
    return response.data;
}
