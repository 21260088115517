export const setupUserAnalytics = function (user: any, email: string, workspaceId: string) {
    //@ts-ignore
    if (typeof window !== 'undefined' && window.heap) {
        //@ts-ignore
        const heap = window.heap;
        heap.identify(`${email}`);
        heap.addUserProperties({ workspaceId: workspaceId, ...user });
    }
};

export const trackHeapEvent = function (eventName: string, properties: any) {
    //@ts-ignore
    if (typeof window !== 'undefined' && window.heap) {
        //@ts-ignore
        const heap = window.heap;
        heap.track(eventName, properties);
    }
};
