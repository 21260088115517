import {
    TestType,
    EnvType,
    ScheduleType,
    TagType,
    PlanType,
    WorkspaceSettingsType,
    FilesVariablePairType
} from '@shaztra/types/DBTypes';
import { UIVariablePairType } from '@shaztra/types/UITypes';
import { AbortPlanAPIData, PendingExecutionsAPIData } from '../../async/actionListeners/APITypes';
import {
    SIDEBAR_ITEM_SELECTION_ACTION,
    SIDEBAR_TOGGLE_COLLAPSE_ACTION,
    SET_WORKSPACE_ID_ACTION,
    SET_TESTFILE_ID_ACTION,
    SET_PLAN_ID_ACTION,
    SET_TESTS_LIST_ACTION,
    SET_ENVS_LIST_ACTION,
    SET_SCHEDULES_LIST_ACTION,
    SET_TAGS_LIST_ACTION,
    SET_PLANS_LIST_ACTION,
    UPDATE_API_TEST_STEP_DATA_ACTION,
    SET_TEST_EXECUTION_RESULT_ACTION,
    SET_OPEN_MENUS_ACTION,
    UPDATE_TEST_DATA_IN_STATE,
    UPDATE_ENV_DATA_IN_STATE,
    UPDATE_SCHEDULE_DATA_IN_STATE,
    UPDATE_TAG_DATA_IN_STATE,
    UPDATE_PLAN_DATA_IN_STATE,
    WAIT,
    WAIT_DONE,
    SET_EXECUTION_HISTORY_RESULTS,
    SET_USER_DATA,
    SET_PENDING_EXECUTIONS_RESULTS,
    SET_USER_NOTIFICATION_DATA,
    SET_NOTIFICATION_AS_READ,
    UPDATE_WAITLIST_DATA,
    ERROR_MESSAGE_DATA,
    REMOVE_ERROR_MESSAGE_DATA,
    GOTO,
    GOTO_DONE,
    UPDATE_PLANLIST_FILTER_IN_STATE,
    UPDATE_RUNSUMMARY_FILTER_IN_STATE,
    UPDATE_RUNDETAILS_FILTER_IN_STATE,
    UPDATE_TESTLIST_FILTER_IN_STATE,
    ADD_TO_ROUTER_HISTORY,
    POP_ROUTER_HISTORY,
    SET_PLAN_REPORTS_LIST_ACTION,
    SET_TEST_PLAN_REPORTS_LIST_ACTION,
    SET_PLAN_REPORT_ACTION,
    SET_TEST_PLAN_REPORT_ACTION,
    SET_ABORT_PLAN_RESULTS,
    COPY_PLAN_ACTION,
    COPY_TEST_ACTION,
    UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE,
    SET_GLOBAL_VARIABLE_LIST,
    SET_GLOBAL_FILE_LIST
} from '../actionConstants';
import {
    SidebarItemSelectionAction,
    SidebarToggleCollapseAction,
    SetWorkspaceIdAction,
    SetTestFileIdAction,
    SetPlanIdAction,
    UpdateApiTestsDataAction,
    UpdateApiEnvsDataAction,
    UpdateApiSchedulesDataAction,
    UpdateApiTagsDataAction,
    UpdateApiPlansDataAction,
    UpdateApiTestStepDataAction,
    UpdateApiTestExecutionResultDataAction,
    ExecutionResultResponse,
    SetOpenMenusAction,
    UpdateTestDataInStateAction,
    UpdateEnvDataInStateAction,
    UpdateScheduleDataInStateAction,
    UpdateTagDataInStateAction,
    UpdatePlanDataInStateAction,
    WaitAction,
    WaitDoneAction,
    SetExecutionHistoryAction,
    UserDataType,
    SetUserData,
    SetUserNotificationData,
    SetNotificationAsRead,
    UpdateWaitlistData,
    ErrorMessageData,
    RemoveErrorMessageAction,
    GotoAction,
    GotoDoneAction,
    TestListFilterAction,
    PlanListFilterAction,
    RunSummaryFilterAction,
    RunDetailsFilterAction,
    AddToRouterHistoryAction,
    PopRouterHistoryAction,
    UpdateApiAllPlanReportsDataAction,
    UpdateApiTestPlanReportDataAction,
    UpdateApiAllTestPlanReportsDataAction,
    UpdateApiPlanReportDataAction,
    UpdateAbortPlanStateAction,
    CopyPlanAction,
    CopyTestAction,
    UpdateDefaultSettingsDataInState,
    SetGlobalVariableListAction,
    SetGlobalFileListAction
} from '../actionTypes';
export const sidebarItemSelectionAction = (highlightKey: string): SidebarItemSelectionAction => ({
    type: SIDEBAR_ITEM_SELECTION_ACTION,
    payload: {
        highlightKey
    }
});
export const sidebarToggleCollapseAction = (): SidebarToggleCollapseAction => ({
    type: SIDEBAR_TOGGLE_COLLAPSE_ACTION,
    payload: {}
});

export const setWorkspaceId = (id: string): SetWorkspaceIdAction => ({
    type: SET_WORKSPACE_ID_ACTION,
    payload: {
        workspaceId: id
    }
});

export const setTestFileId = (id: string): SetTestFileIdAction => ({
    type: SET_TESTFILE_ID_ACTION,
    payload: {
        testFileId: id
    }
});

export const setPlanId = (id: string): SetPlanIdAction => ({
    type: SET_PLAN_ID_ACTION,
    payload: {
        planId: id
    }
});

export const setOpenMenus = (openMenus: string[]): SetOpenMenusAction => ({
    type: SET_OPEN_MENUS_ACTION,
    payload: {
        openMenus
    }
});

// TODO:change this `any`
export const updateApiTestDataState = (data: any): UpdateApiTestsDataAction => ({
    type: SET_TESTS_LIST_ACTION,
    payload: {
        tests: data
    }
});

export const updateApiEnvDataState = (data: any): UpdateApiEnvsDataAction => ({
    type: SET_ENVS_LIST_ACTION,
    payload: {
        envList: data
    }
});

export const updateApiScheduleDataState = (data: any): UpdateApiSchedulesDataAction => ({
    type: SET_SCHEDULES_LIST_ACTION,
    payload: {
        scheduleList: data
    }
});

export const updateApiTagDataState = (data: any): UpdateApiTagsDataAction => ({
    type: SET_TAGS_LIST_ACTION,
    payload: {
        tagList: data
    }
});

export const updateApiPlanDataState = (data: any): UpdateApiPlansDataAction => ({
    type: SET_PLANS_LIST_ACTION,
    payload: {
        planList: data
    }
});

export const updateApiTestStepState = (id: string, stepList: string[]): UpdateApiTestStepDataAction => ({
    type: UPDATE_API_TEST_STEP_DATA_ACTION,
    payload: {
        id,
        stepList
    }
});

// -------- Plan Reports --------

export const updateApiAllPlanReportsDataState = (data: any): UpdateApiAllPlanReportsDataAction => ({
    type: SET_PLAN_REPORTS_LIST_ACTION,
    payload: {
        planReportList: data
    }
});

export const updateApiPlanReportDataState = (data: any): UpdateApiPlanReportDataAction => ({
    type: SET_PLAN_REPORT_ACTION,
    payload: {
        planReport: data
    }
});

export const updateApiAllTestPlanReportsDataState = (data: any): UpdateApiAllTestPlanReportsDataAction => ({
    type: SET_TEST_PLAN_REPORTS_LIST_ACTION,
    payload: {
        planReportList: data
    }
});

export const updateApiTestPlanReportDataState = (data: any): UpdateApiTestPlanReportDataAction => ({
    type: SET_TEST_PLAN_REPORT_ACTION,
    payload: {
        planReport: data
    }
});

/**
 * @deprecated
 * @param data
 * @param testId
 * @param workspaceId
 */
export const updateApiTestExecutionResultState = (
    data: ExecutionResultResponse,
    testId: string,
    workspaceId: string
): UpdateApiTestExecutionResultDataAction => ({
    type: SET_TEST_EXECUTION_RESULT_ACTION,
    payload: {
        data,
        testId,
        workspaceId
    }
});

export const updatePendingExecutionsState = (
    data: PendingExecutionsAPIData,
    testId?: string,
    workspaceId?: string,
    type?: string
) => ({
    type: SET_PENDING_EXECUTIONS_RESULTS,
    payload: {
        data,
        testId,
        workspaceId,
        type
    }
});

export const updateAbortPlanState = (
    data: AbortPlanAPIData,
    planReportId?: string,
    workspaceId?: string
): UpdateAbortPlanStateAction => ({
    type: SET_ABORT_PLAN_RESULTS,
    payload: {
        data,
        planReportId,
        workspaceId
    }
});

export const copyPlanAction = (workspaceId: string, currentPlanId: string): CopyPlanAction => ({
    type: COPY_PLAN_ACTION,
    payload: {
        currentPlanId,
        workspaceId
    }
});

export const copyTestAction = (workspaceId: string, currentTestId: string): CopyTestAction => ({
    type: COPY_TEST_ACTION,
    payload: {
        currentTestId,
        workspaceId
    }
});

export const updateTestDataInState = (
    workspaceId: string,
    testId: string,
    testData: TestType
): UpdateTestDataInStateAction => ({
    type: UPDATE_TEST_DATA_IN_STATE,
    payload: {
        testData,
        testId,
        workspaceId
    }
});

export const updateEnvDataInState = (
    workspaceId: string,
    envId: string,
    envData: EnvType
): UpdateEnvDataInStateAction => ({
    type: UPDATE_ENV_DATA_IN_STATE,
    payload: {
        envData,
        envId,
        workspaceId
    }
});

export const updateScheduleDataInState = (
    workspaceId: string,
    scheduleId: string,
    scheduleData: ScheduleType
): UpdateScheduleDataInStateAction => ({
    type: UPDATE_SCHEDULE_DATA_IN_STATE,
    payload: {
        scheduleData,
        scheduleId,
        workspaceId
    }
});

export const updateTagDataInState = (
    workspaceId: string,
    tagId: string,
    tagData: TagType
): UpdateTagDataInStateAction => ({
    type: UPDATE_TAG_DATA_IN_STATE,
    payload: {
        tagData,
        tagId,
        workspaceId
    }
});

export const updatePlanDataInState = (
    workspaceId: string,
    planId: string,
    planData: PlanType
): UpdatePlanDataInStateAction => ({
    type: UPDATE_PLAN_DATA_IN_STATE,
    payload: {
        planData,
        planId,
        workspaceId
    }
});

export const updateDefaultSettingsDataInState = (
    workspaceId: string,
    settingsData: WorkspaceSettingsType
): UpdateDefaultSettingsDataInState => ({
    type: UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE,
    payload: {
        workspaceId,
        settingsData
    }
});

export const waitAction = (): WaitAction => ({
    type: WAIT
});

export const waitDoneAction = (): WaitDoneAction => ({
    type: WAIT_DONE
});

export const gotoAction = (path: string): GotoAction => ({
    type: GOTO,
    payload: { path }
});

export const gotoDoneAction = (): GotoDoneAction => ({
    type: GOTO_DONE
});

export const popRouterHistory = (): PopRouterHistoryAction => ({
    type: POP_ROUTER_HISTORY
});

export const addToRouterHistory = (url: string): AddToRouterHistoryAction => ({
    type: ADD_TO_ROUTER_HISTORY,
    payload: {
        url
    }
});

export const removeErrorMessageAction = (): RemoveErrorMessageAction => ({
    type: REMOVE_ERROR_MESSAGE_DATA
});

export const setExecutionHistoryState = (executionListByTest, executionList): SetExecutionHistoryAction => ({
    type: SET_EXECUTION_HISTORY_RESULTS,
    payload: {
        executionListByTest,
        executionList
    }
});

export const setUserDataAction = (userData: UserDataType): SetUserData => ({
    type: SET_USER_DATA,
    payload: {
        userData
    }
});

export const setUserNotificationDataAction = (notificationData: any[]): SetUserNotificationData => ({
    type: SET_USER_NOTIFICATION_DATA,
    payload: {
        notificationData
    }
});

export const setNotificationAsReadAction = (notificationId: string): SetNotificationAsRead => ({
    type: SET_NOTIFICATION_AS_READ,
    payload: {
        notificationId
    }
});

export const updateWaitlistState = (waitlistData: any): UpdateWaitlistData => ({
    type: UPDATE_WAITLIST_DATA,
    payload: {
        waitlistData
    }
});

export const errorMessageAction = (message: string): ErrorMessageData => ({
    type: ERROR_MESSAGE_DATA,
    payload: {
        message
    }
});

export const testListFilterAction = (testList): TestListFilterAction => ({
    type: UPDATE_TESTLIST_FILTER_IN_STATE,
    payload: {
        testList: {
            searchText: testList.searchText,
            selectedTag: testList.selectedTag
        }
    }
});

export const planListFilterAction = (planList): PlanListFilterAction => ({
    type: UPDATE_PLANLIST_FILTER_IN_STATE,
    payload: {
        planList: {
            searchText: planList.searchText,
            selectedTag: planList.selectedTag
        }
    }
});

export const runSummaryFilterAction = (runSummary: object): RunSummaryFilterAction => ({
    type: UPDATE_RUNSUMMARY_FILTER_IN_STATE,
    payload: {
        runSummary
    }
});

export const runDetailsFilterAction = (runDetails: object): RunDetailsFilterAction => ({
    type: UPDATE_RUNDETAILS_FILTER_IN_STATE,
    payload: {
        runDetails
    }
});

export const setGlobalVariableListAction = (globalVariableList: UIVariablePairType[]): SetGlobalVariableListAction => ({
    type: SET_GLOBAL_VARIABLE_LIST,
    payload: {
        globalVariableList
    }
});

export const setGlobalFileListAction = (globalFileList: FilesVariablePairType[]): SetGlobalFileListAction => ({
    type: SET_GLOBAL_FILE_LIST,
    payload: {
        globalFileList
    }
});
