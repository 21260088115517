export const TERMINAL_STATUS = {
    new: false,
    running: false,
    passed: true,
    failed: true,
    waiting: true,
    aborted: true,
    syserr: true,
    skipped: true
};
