import { TestType, EnvType, ScheduleType, TagType, WorkspaceSettingsType } from '@shaztra/types/DBTypes';
import { UIPlanType } from '@shaztra/types/UITypes';
import {
    CREATE_TEST_API,
    DELETE_TEST_API,
    RUN_TEST_API,
    GET_ALL_TESTS_API,
    GET_JOIN_WAITLIST_API,
    GET_PENDING_EXECUTIONS_API,
    GET_RUN_HISTORY_API,
    GET_TEST_STEPS_API,
    POST_JOIN_WAITLIST_API,
    SEND_FEEDBACK_API,
    UPDATE_TEST_API,
    CREATE_ENV_API,
    DELETE_ENV_API,
    GET_ALL_ENVS_API,
    GET_ENV_API,
    UPDATE_ENV_API,
    CREATE_SCHEDULE_API,
    DELETE_SCHEDULE_API,
    GET_ALL_SCHEDULES_API,
    GET_SCHEDULE_API,
    UPDATE_SCHEDULE_API,
    CREATE_TAG_API,
    DELETE_TAG_API,
    GET_ALL_TAGS_API,
    GET_TAG_API,
    UPDATE_TAG_API,
    CREATE_PLAN_API,
    DELETE_PLAN_API,
    GET_ALL_PLANS_API,
    GET_PLAN_API,
    UPDATE_PLAN_API,
    GET_ALL_TEST_PLAN_REPORTS_API,
    GET_TEST_PLAN_REPORT_API,
    GET_ALL_PLAN_REPORTS_API,
    GET_PLAN_REPORT_API,
    RUN_PLAN_API,
    ABORT_RUN_PLAN_API,
    GET_DEFAULT_SETTINGS_API,
    UPDATE_DEFAULT_SETTINGS_API
} from '../actionConstants';
import {
    RunTestAction,
    GetAllTestsDataAction,
    GetPendingExecutionsAction,
    GetRunHistoryAction,
    GetTestDataAction,
    GetAllEnvsDataAction,
    GetEnvDataAction,
    GetAllSchedulesDataAction,
    GetScheduleDataAction,
    GetAllTagsDataAction,
    GetTagDataAction,
    GetAllPlansDataAction,
    GetPlanDataAction,
    GetAllTestPlanReportsDataAction,
    GetAllPlanReportsDataAction,
    GetPlanReportDataAction,
    GetTestPlanReportDataAction,
    RunPlanAction,
    AbortRunPlanAction,
    GetDefaultSettingsDataAction,
    UpdateDefaultSettingsDataAction
} from '../actionTypes';

// ------------------ Test Action Creators --------------------
export const getAllTestsAction = (workspaceId: string): GetAllTestsDataAction => ({
    type: GET_ALL_TESTS_API,
    payload: {
        workspaceId
    }
});

export const getExecutionHistoryAction = (workspaceId: string): GetRunHistoryAction => ({
    type: GET_RUN_HISTORY_API,
    payload: {
        workspaceId
    }
});

export const getPendingExecutionsAction = (workspaceId: string): GetPendingExecutionsAction => ({
    type: GET_PENDING_EXECUTIONS_API,
    payload: {
        workspaceId
    }
});

export const getTestStepsAction = (workspaceId: string, testId: string): GetTestDataAction => ({
    type: GET_TEST_STEPS_API,
    payload: {
        workspaceId,
        testId
    }
});

export const runTestByIdAction = (workspaceId: string, testId: string): RunTestAction => ({
    type: RUN_TEST_API,
    payload: {
        workspaceId,
        testId
    }
});

export const runPlanByIdAction = (workspaceId: string, planId: string): RunPlanAction => ({
    type: RUN_PLAN_API,
    payload: {
        workspaceId,
        planId
    }
});

export const abortRunPlanByIdAction = (workspaceId: string, planReportId: string): AbortRunPlanAction => ({
    type: ABORT_RUN_PLAN_API,
    payload: {
        workspaceId,
        planReportId
    }
});

export const createTestDataAction = (workspaceId: string, testData: TestType) => ({
    type: CREATE_TEST_API,
    payload: {
        testData,
        workspaceId
    }
});

export const updateTestDataAction = (workspaceId: string, testId: string, testData: TestType) => ({
    type: UPDATE_TEST_API,
    payload: {
        testData,
        testId,
        workspaceId
    }
});

export const deleteTestAction = (workspaceId: string, testId: string) => ({
    type: DELETE_TEST_API,
    payload: {
        testId,
        workspaceId
    }
});

// ------------------ Environment Action Creators --------------------

export const getAllEnvsAction = (workspaceId: string): GetAllEnvsDataAction => ({
    type: GET_ALL_ENVS_API,
    payload: {
        workspaceId
    }
});

export const getEnvDataAction = (workspaceId: string, envId: string): GetEnvDataAction => ({
    type: GET_ENV_API,
    payload: {
        workspaceId,
        envId
    }
});

export const createEnvDataAction = (workspaceId: string, envData: EnvType) => ({
    type: CREATE_ENV_API,
    payload: {
        envData,
        workspaceId
    }
});

export const updateEnvDataAction = (workspaceId: string, envId: string, envData: EnvType) => ({
    type: UPDATE_ENV_API,
    payload: {
        envData,
        envId,
        workspaceId
    }
});

export const deleteEnvAction = (workspaceId: string, envId: string) => ({
    type: DELETE_ENV_API,
    payload: {
        envId,
        workspaceId
    }
});

// ------------------ Schedule Action Creators --------------------

export const getAllSchedulesAction = (workspaceId: string): GetAllSchedulesDataAction => ({
    type: GET_ALL_SCHEDULES_API,
    payload: {
        workspaceId
    }
});

export const getScheduleDataAction = (workspaceId: string, scheduleId: string): GetScheduleDataAction => ({
    type: GET_SCHEDULE_API,
    payload: {
        workspaceId,
        scheduleId
    }
});

export const createScheduleDataAction = (workspaceId: string, scheduleData: ScheduleType) => ({
    type: CREATE_SCHEDULE_API,
    payload: {
        scheduleData,
        workspaceId
    }
});

export const updateScheduleDataAction = (workspaceId: string, scheduleId: string, scheduleData: ScheduleType) => ({
    type: UPDATE_SCHEDULE_API,
    payload: {
        scheduleData,
        scheduleId,
        workspaceId
    }
});

export const deleteScheduleAction = (workspaceId: string, scheduleId: string) => ({
    type: DELETE_SCHEDULE_API,
    payload: {
        scheduleId,
        workspaceId
    }
});

// ------------------ Tag Action Creators --------------------

export const getAllTagsAction = (workspaceId: string): GetAllTagsDataAction => ({
    type: GET_ALL_TAGS_API,
    payload: {
        workspaceId
    }
});

export const getTagDataAction = (workspaceId: string, tagId: string): GetTagDataAction => ({
    type: GET_TAG_API,
    payload: {
        workspaceId,
        tagId
    }
});

export const createTagDataAction = (workspaceId: string, tagData: TagType) => ({
    type: CREATE_TAG_API,
    payload: {
        tagData,
        workspaceId
    }
});

export const updateTagDataAction = (workspaceId: string, tagId: string, tagData: TagType) => ({
    type: UPDATE_TAG_API,
    payload: {
        tagData,
        tagId,
        workspaceId
    }
});

export const deleteTagAction = (workspaceId: string, tagId: string) => ({
    type: DELETE_TAG_API,
    payload: {
        tagId,
        workspaceId
    }
});

// ------------------ Plan Action Creators --------------------

export const getAllPlansAction = (workspaceId: string): GetAllPlansDataAction => ({
    type: GET_ALL_PLANS_API,
    payload: {
        workspaceId
    }
});

export const getPlanDataAction = (workspaceId: string, planId: string): GetPlanDataAction => ({
    type: GET_PLAN_API,
    payload: {
        workspaceId,
        planId
    }
});

export const createPlanDataAction = (workspaceId: string, planData: UIPlanType) => ({
    type: CREATE_PLAN_API,
    payload: {
        planData,
        workspaceId
    }
});

export const updatePlanDataAction = (workspaceId: string, planId: string, planData: UIPlanType) => ({
    type: UPDATE_PLAN_API,
    payload: {
        planData,
        planId,
        workspaceId
    }
});

export const deletePlanAction = (workspaceId: string, planId: string) => ({
    type: DELETE_PLAN_API,
    payload: {
        planId,
        workspaceId
    }
});

// ------------------ Plan Report Action Creators --------------------

export const getAllTestPlanReportsAction = (workspaceId: string): GetAllTestPlanReportsDataAction => ({
    type: GET_ALL_TEST_PLAN_REPORTS_API,
    payload: {
        workspaceId
    }
});

export const getTestPlanReportDataAction = (
    workspaceId: string,
    planExecutionId: string
): GetTestPlanReportDataAction => ({
    type: GET_TEST_PLAN_REPORT_API,
    payload: {
        workspaceId,
        planExecutionId
    }
});

export const getAllPlanReportsAction = (workspaceId: string): GetAllPlanReportsDataAction => ({
    type: GET_ALL_PLAN_REPORTS_API,
    payload: {
        workspaceId
    }
});

export const getPlanReportDataAction = (workspaceId: string, planExecutionId: string): GetPlanReportDataAction => ({
    type: GET_PLAN_REPORT_API,
    payload: {
        workspaceId,
        planExecutionId
    }
});

// ------------------ Settings Action Creators --------------------

export const getDefaultSettingsAction = (workspaceId: string): GetDefaultSettingsDataAction => ({
    type: GET_DEFAULT_SETTINGS_API,
    payload: {
        workspaceId
    }
});

export const updateDefaultSettingsAction = (
    workspaceId: string,
    settingsData: WorkspaceSettingsType
): UpdateDefaultSettingsDataAction => ({
    type: UPDATE_DEFAULT_SETTINGS_API,
    payload: {
        workspaceId,
        settingsData
    }
});

// ------------------ Other Action Creators --------------------

export const sendFeedbackAction = (workspaceId: string, feedbackData: any) => ({
    type: SEND_FEEDBACK_API,
    payload: {
        feedbackData,
        workspaceId
    }
});

export const getJoinWaitlistAction = (workspaceId: string, userId: string) => ({
    type: GET_JOIN_WAITLIST_API,
    payload: {
        userId,
        workspaceId
    }
});

export const joinWaitlistAction = (workspaceId: string, userId: string, name: string) => ({
    type: POST_JOIN_WAITLIST_API,
    payload: {
        userId,
        name,
        workspaceId
    }
});
