import React, { useState, useEffect, useContext, createContext } from 'react';
import { setCookie, destroyCookie } from 'nookies';
import { firebaseClient } from './firebaseClient';

const AuthContext = createContext<{ user: firebaseClient.User | null; fetched: boolean }>({
    user: null,
    fetched: false
});

export function AuthProvider({ children }: any) {
    const [user, setUser] = useState<firebaseClient.User | null>(null);
    const [fetched, setFetched] = useState<boolean>(false);

    useEffect(() => {
        return firebaseClient.auth().onIdTokenChanged(async (user) => {
            // const cookies = parseCookies();
            setFetched(true);
            if (!user) {
                setUser(null);
                destroyCookie(null, 'token', { path: '/' });
                return;
            }

            const token = await user.getIdToken();
            setUser(user);
            setCookie(null, 'token', token, { path: '/' });
        });
    }, []);

    // force refresh the token every 45 minutes
    useEffect(() => {
        const handle = setInterval(async () => {
            const user = firebaseClient.auth().currentUser;
            if (user) await user.getIdToken(true);
        }, 45 * 60 * 1000);
        return () => clearInterval(handle);
    }, []);

    return <AuthContext.Provider value={{ user, fetched }}>{children}</AuthContext.Provider>;
}

export const useAuth = () => {
    return useContext(AuthContext);
};
