import { GroupListType } from '@shaztra/types';
import { UIGroupReportData, UIScheduleType, UIPlanType } from '@shaztra/types/UITypes';
import { PlanType, ScheduleType } from '@shaztra/types/DBTypes';

export function randomString() {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export function buildGroupListForUI(groupList: GroupListType[] = [], allTestsMap: Record<string, any>) {
    const groupListData: UIGroupReportData[] = [];
    groupList.forEach((group) => {
        const { name: groupName, isSequential, testList } = group;
        if (testList) {
            const totalNumberOfTests = testList?.length;

            const testListData = [];
            testList.forEach((testId) => {
                const test = allTestsMap[testId];
                if (test) {
                    const { name: testName, description: testDescription, tags: testTagList, steps: stepList } = test;
                    const testRunStatus = 'new'; // hack
                    const numberOfStepsPassed = -1,
                        totalNumberOfSteps = stepList.length;
                    let stepListData = [];
                    if (stepList) {
                        stepListData = stepList.map((step, index) => {
                            return {
                                stepIndex: index,
                                step: step,
                                runStatus: step.runStatus || 'new',
                                screenshot: null,
                                screenshotUrl: null,
                                async: false
                            };
                        });
                    }

                    testListData.push({
                        testId,
                        testName,
                        testDescription,
                        testTagList,
                        testRunStatus,
                        numberOfStepsPassed,
                        totalNumberOfSteps,
                        stepList: stepListData
                    });
                }
            });

            groupListData.push({
                key: randomString(),
                groupName,
                isSequential,
                groupRunStatus: 'new', // this is a hack, we need some value to be present
                numberOfTestsPassed: -1,
                totalNumberOfTests,
                testListData
            });
        }
    });
    return groupListData;
}

export function buildGroupListForAPI(groupList: UIGroupReportData[] = [], workspaceId: string) {
    const groupListData: GroupListType[] = [];
    groupList.forEach((group) => {
        const { groupName, isSequential, testListData } = group;
        let updatedTestList: string[] = [];
        // clean up testListData
        if (testListData && testListData.length > 0) {
            testListData.forEach((test) => {
                // we create empty test rows with 'new' as id
                if (test.testId !== 'new') {
                    updatedTestList.push(test.testId);
                }
            });
        }
        // now we have cleaned up testList, but this means testList can still be empty
        // and we only create a group if there are tests in it
        if (updatedTestList && updatedTestList.length > 0) {
            const groupListItem: GroupListType = {
                workspaceId,
                name: groupName,
                isSequential,
                testList: updatedTestList,
                status: 'active'
            };
            groupListData.push(groupListItem);
        }
    });
    return groupListData;
}

export function addScheduleAsObjectsToPlan(plan: PlanType, allSchedulesMap: Record<string, ScheduleType>): UIPlanType {
    if (!plan) {
        return null;
    }
    const scheduleListForUI = buildScheduleListForUI(plan.schedules, plan.workspaceId, allSchedulesMap);
    // build the plan object
    const planForUI: UIPlanType = {
        ...plan,
        schedules: scheduleListForUI
    };

    return planForUI;
}

export function buildScheduleListForUI(
    scheduleList: string[] = [],
    workspaceId,
    allSchedulesMap: Record<string, ScheduleType>
): UIScheduleType[] {
    const SCHEDULE: ScheduleType = {
        status: 'active',
        workspaceId,
        cronString: ''
    };
    const scheduleListData: UIScheduleType[] = [];
    scheduleList.forEach((schedule) => {
        let { cronString, ...rest } = allSchedulesMap[schedule] || SCHEDULE;
        let obj: UIScheduleType = {
            ...rest
        };
        scheduleListData.push(obj);
    });

    return scheduleListData;
}
