import { randomString } from 'src/ui/app/screens/Plans/planUtils';
import {
    SET_TESTS_LIST_ACTION,
    SET_ENVS_LIST_ACTION,
    SET_SCHEDULES_LIST_ACTION,
    SET_TAGS_LIST_ACTION,
    SET_PLANS_LIST_ACTION,
    SET_WORKSPACE_ID_ACTION,
    SIDEBAR_ITEM_SELECTION_ACTION,
    SIDEBAR_TOGGLE_COLLAPSE_ACTION,
    SET_TEST_EXECUTION_RESULT_ACTION,
    UPDATE_TESTLIST_FILTER_IN_STATE,
    UPDATE_PLANLIST_FILTER_IN_STATE,
    UPDATE_RUNSUMMARY_FILTER_IN_STATE,
    UPDATE_RUNDETAILS_FILTER_IN_STATE,
    HYDRATE,
    SET_OPEN_MENUS_ACTION,
    UPDATE_TEST_DATA_IN_STATE,
    UPDATE_ENV_DATA_IN_STATE,
    UPDATE_SCHEDULE_DATA_IN_STATE,
    UPDATE_TAG_DATA_IN_STATE,
    UPDATE_PLAN_DATA_IN_STATE,
    UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE,
    WAIT,
    WAIT_DONE,
    SET_EXECUTION_HISTORY_RESULTS,
    SET_USER_DATA,
    SET_PENDING_EXECUTIONS_RESULTS,
    SET_USER_NOTIFICATION_DATA,
    SET_NOTIFICATION_AS_READ,
    UPDATE_WAITLIST_DATA,
    ERROR_MESSAGE_DATA,
    REMOVE_ERROR_MESSAGE_DATA,
    GOTO_DONE,
    ADD_TO_ROUTER_HISTORY,
    POP_ROUTER_HISTORY,
    SET_PLAN_REPORTS_LIST_ACTION,
    SET_PLAN_REPORT_ACTION,
    SET_TEST_PLAN_REPORTS_LIST_ACTION,
    SET_TEST_PLAN_REPORT_ACTION,
    SET_ABORT_PLAN_RESULTS,
    COPY_PLAN_ACTION,
    COPY_TEST_ACTION,
    SET_GLOBAL_VARIABLE_LIST,
    SET_GLOBAL_FILE_LIST
} from '../actionConstants';
import { RootState, SyncActions } from '../actionTypes';
import { TERMINAL_STATUS } from '../runStatusUtil';

const initialState: RootState = {
    uiState: {
        sidebar: {
            highlightKey: 'overview',
            openMenus: []
        }
    },
    uiFilters: {
        testList: {
            selectedTag: '',
            searchText: ''
        },
        planList: {
            selectedTag: '',
            searchText: ''
        },
        runSummary: {
            selectedTags: [],
            searchText: ''
        },
        runDetails: {
            selectedTags: [],
            searchText: ''
        }
    },
    tests: {},
    planList: {},
    inMemoryPlanList: {},
    inMemoryTestList: {},
    tagList: {},
    envList: {},
    settings: {
        default: {}
    },
    scheduleList: {},
    executions: {
        inProgressExecutions: {},
        inQueueExecutions: {},
        inAbortedExecutions: {}
    },
    reports: {
        tests: [],
        plans: []
    },
    reportHistory: {
        reportListByTest: {},
        reportListByPlan: {}
    },
    workspaceId: null,
    wait: false,
    executionHistory: {
        executionListByTest: {},
        executionList: []
    },
    userNotificationData: [],
    error: null,
    goto: null,
    routerHistory: [],
    globalVariableList: [],
    globalFileList: []
};

const actionListeners = (state: RootState = initialState, action: SyncActions): RootState => {
    switch (action.type) {
        case HYDRATE:
            // Attention! This will overwrite client state! Real apps should use proper reconciliation.
            return { ...state, ...action.payload };
        case WAIT:
            return { ...state, wait: true };
        case WAIT_DONE:
            return { ...state, wait: false };
        case GOTO_DONE:
            return { ...state, goto: null };
        case ERROR_MESSAGE_DATA: {
            const message = action.payload.message;
            return { ...state, error: message };
        }
        case REMOVE_ERROR_MESSAGE_DATA: {
            return { ...state, error: null };
        }
        case SIDEBAR_ITEM_SELECTION_ACTION: {
            const newHighlightKey = action.payload.highlightKey;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: newHighlightKey
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            return { ...state, uiState: { ...uiState } };
        }
        case SIDEBAR_TOGGLE_COLLAPSE_ACTION: {
            const sidebarState = state.uiState.sidebar;
            const collapsed = !sidebarState.collapsed;
            let openMenus = [...state.uiState.sidebar.openMenus];
            if (collapsed === true) {
                openMenus = [];
            }
            const sidebar = { ...state.uiState.sidebar, collapsed: collapsed, openMenus }; // this overrides collapsed of sidebar
            return { ...state, uiState: { ...state.uiState, sidebar } };
        }
        case SET_WORKSPACE_ID_ACTION: {
            const workspaceId = action.payload.workspaceId;
            return { ...state, workspaceId };
        }
        case SET_OPEN_MENUS_ACTION: {
            const openMenus = [...action.payload.openMenus];
            const sidebar = { ...state.uiState.sidebar, openMenus };
            const uiState = { ...state.uiState, sidebar };
            return { ...state, uiState };
        }
        case SET_TESTS_LIST_ACTION: {
            const testsDataArray = action.payload.tests;
            const testIdToTestDataMap = (testsDataArray as Array<any>).reduce((acc, currTest) => {
                const { id } = currTest;
                acc[id] = { ...currTest };
                return acc;
            }, {});
            return {
                ...state,
                tests: { ...testIdToTestDataMap }
            };
        }
        // case UPDATE_API_TEST_STEP_DATA_ACTION: {
        //     const { id, stepList } = action.payload;
        //     const currentApiData = state.apiData;
        //     let currentStepsData = currentApiData.steps;
        //     if (!currentStepsData) {
        //         currentStepsData = {};
        //     }
        //     const newStepsData = { ...currentStepsData };
        //     newStepsData[id] = stepList;
        //     return {
        //         ...state,
        //         apiData: { ...currentApiData, steps: { ...newStepsData } },
        //     };
        // }
        case SET_TEST_EXECUTION_RESULT_ACTION: {
            const { testId, data } = action.payload;
            const executionId = data.executionDocId;
            const executionsData = { ...state.executions };
            let currentTestsData = {};
            if (executionsData[testId]) {
                currentTestsData = {
                    ...executionsData[testId],
                    [executionId]: data
                };
            } else {
                currentTestsData = {
                    [executionId]: data
                };
            }
            executionsData[testId] = currentTestsData;
            return {
                ...state,
                executions: { ...executionsData }
            };
        }
        case SET_PENDING_EXECUTIONS_RESULTS: {
            const { data, type } = action.payload;
            /*{
                type: 'plan'|'test,
                id: planId|testId,
                reportId: planReportDoc.id,
                executionId: planExecutionDoc.id
            }*/
            if (data) {
                let id, reportId;
                if (type === 'plan') {
                    id = data.planId;
                    reportId = data.planRunId;
                } else {
                    id = data.testId;
                    reportId = data.testRunId;
                }
                const executionInfo = {
                    type,
                    id,
                    reportId,
                    executionId: reportId
                };
                // this needs to be added to inQueueExecutions
                const currentExecutionsData = { ...state.executions };
                const currentInQueueExecutions = currentExecutionsData.inQueueExecutions;
                if (currentInQueueExecutions[id]) {
                    currentInQueueExecutions[id].push({ ...executionInfo });
                } else {
                    currentInQueueExecutions[id] = [{ ...executionInfo }];
                }
                const newExecutionsData = {
                    ...currentExecutionsData,
                    inQueueExecutions: { ...currentInQueueExecutions }
                };
                return {
                    ...state,
                    executions: newExecutionsData
                };
            }
            return state;
        }
        case UPDATE_TEST_DATA_IN_STATE: {
            const { testId, testData } = action.payload;
            const tests = { ...state.tests };
            tests[testId] = testData;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: testId
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            const workspaceId = state.workspaceId;
            // const goto = `/app/${workspaceId}/${testId}`;
            return { ...state, uiState: { ...uiState }, tests, inMemoryTestList: {} };
        }

        case UPDATE_ENV_DATA_IN_STATE: {
            const { envId, envData } = action.payload;
            const envList = { ...state.envList };
            envList[envId] = envData;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: 'testPlan'
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            const workspaceId = state.workspaceId;
            // const goto = `/app/${workspaceId}/plans`;
            return { ...state, uiState: { ...uiState }, envList };
        }

        case UPDATE_SCHEDULE_DATA_IN_STATE: {
            const { scheduleId, scheduleData } = action.payload;
            const scheduleList = { ...state.scheduleList };
            scheduleList[scheduleId] = scheduleData;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: 'testPlan'
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            const workspaceId = state.workspaceId;
            // const goto = `/app/${workspaceId}/schedules`;
            return { ...state, uiState: { ...uiState }, scheduleList };
        }
        case UPDATE_TAG_DATA_IN_STATE: {
            const { tagId, tagData } = action.payload;
            const tagList = { ...state.tagList };
            tagList[tagId] = tagData;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: 'testPlan'
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            return { ...state, uiState: { ...uiState }, tagList };
        }
        case UPDATE_PLAN_DATA_IN_STATE: {
            const { planId, planData } = action.payload;
            const planList = { ...state.planList };
            planList[planId] = planData;
            const sidebar = {
                ...state.uiState.sidebar,
                highlightKey: 'testPlan'
            };
            const uiState = {
                ...state.uiState,
                sidebar: { ...sidebar }
            };
            const workspaceId = state.workspaceId;
            return {
                ...state,
                uiState: { ...uiState },
                planList,
                inMemoryPlanList: {} /*goto: `/app/${workspaceId}/plans`*/
            };
        }
        case UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE: {
            const { settingsData } = action.payload;
            const settings = { ...state.settings };
            settings.default = settingsData;
            const workspaceId = state.workspaceId;
            return {
                ...state,
                settings
            };
        }
        case SET_EXECUTION_HISTORY_RESULTS: {
            const { executionListByTest, executionList } = action.payload;
            return {
                ...state,
                executionHistory: {
                    executionListByTest,
                    executionList
                }
            };
        }

        case SET_ENVS_LIST_ACTION: {
            const envsDataArray = action.payload.envList;
            const envIdToEnvDataMap = (envsDataArray as Array<any>).reduce((acc, currEnv) => {
                const { id } = currEnv;
                acc[id] = { ...currEnv };
                return acc;
            }, {});
            return {
                ...state,
                envList: { ...envIdToEnvDataMap }
            };
        }

        case SET_SCHEDULES_LIST_ACTION: {
            const schedulesDataArray = action.payload.scheduleList;
            const scheduleIdToScheduleDataMap = (schedulesDataArray as Array<any>).reduce((acc, currSchedule) => {
                const { id } = currSchedule;
                acc[id] = { ...currSchedule };
                return acc;
            }, {});
            return {
                ...state,
                scheduleList: { ...scheduleIdToScheduleDataMap }
            };
        }

        case SET_TAGS_LIST_ACTION: {
            const tagsDataArray = action.payload.tagList;
            const tagIdToTagDataMap = (tagsDataArray as Array<any>).reduce((acc, currTag) => {
                const { id } = currTag;
                acc[id] = { ...currTag };
                return acc;
            }, {});
            return {
                ...state,
                tagList: { ...tagIdToTagDataMap }
            };
        }

        case SET_PLANS_LIST_ACTION: {
            const plansDataArray = action.payload.planList;
            const planIdToTagDataMap = (plansDataArray as Array<any>).reduce((acc, currPlan) => {
                const { id } = currPlan;
                acc[id] = { ...currPlan };
                return acc;
            }, {});
            return {
                ...state,
                planList: { ...planIdToTagDataMap }
            };
        }

        case SET_USER_DATA: {
            const { userData } = action.payload;
            return {
                ...state,
                userData: {
                    ...userData
                }
            };
        }
        case SET_USER_NOTIFICATION_DATA: {
            const { notificationData } = action.payload;
            return {
                ...state,
                userNotificationData: [...notificationData]
            };
        }
        case UPDATE_WAITLIST_DATA: {
            const { waitlistData } = action.payload;
            return { ...state, waitlistData: { ...waitlistData } };
        }
        case SET_NOTIFICATION_AS_READ: {
            const { notificationId } = action.payload;
            const { userNotificationData } = state;
            const filteredList = userNotificationData.filter((notification) => notification.id === notificationId);
            if (filteredList && filteredList.length === 1) {
                const notificationToChange = filteredList[0];
                const readNotification = { ...notificationToChange, isNew: false };
                const modifiedUserNotificationData = userNotificationData.map((notifications) => {
                    if (notifications.id === notificationId) {
                        return readNotification;
                    } else {
                        return notifications;
                    }
                });
                return {
                    ...state,
                    userNotificationData: [...modifiedUserNotificationData]
                };
            }
            return state;
        }
        case UPDATE_TESTLIST_FILTER_IN_STATE: {
            const newTestList = action.payload.testList;
            const uiFilters = {
                planList: state.uiFilters.planList,
                runSummary: state.uiFilters.runSummary,
                runDetails: state.uiFilters.runDetails,
                testList: {
                    searchText: newTestList.searchText,
                    selectedTag: newTestList.selectedTag
                }
            };

            return { ...state, uiFilters: { ...uiFilters } };
        }

        case UPDATE_PLANLIST_FILTER_IN_STATE: {
            const newPlanList = action.payload.planList;
            const uiFilters = {
                testList: state.uiFilters.testList,
                runSummary: state.uiFilters.runSummary,
                runDetails: state.uiFilters.runDetails,
                planList: {
                    searchText: newPlanList.searchText,
                    selectedTag: newPlanList.selectedTag
                }
            };

            return { ...state, uiFilters: { ...uiFilters } };
        }

        case UPDATE_RUNSUMMARY_FILTER_IN_STATE: {
            const newRunSummary = action.payload.runSummary;
            const uiFilters = {
                testList: state.uiFilters.testList,
                planList: state.uiFilters.planList,
                runDetails: state.uiFilters.runDetails,
                runSummary: {
                    searchText: newRunSummary.searchText,
                    selectedTags: newRunSummary.selectedTags
                }
            };

            return { ...state, uiFilters: { ...uiFilters } };
        }

        case UPDATE_RUNDETAILS_FILTER_IN_STATE: {
            const newRunDetails = action.payload.runDetails;
            const uiFilters = {
                testList: state.uiFilters.testList,
                planList: state.uiFilters.planList,
                runSummary: state.uiFilters.runSummary,
                runDetails: {
                    searchText: newRunDetails.searchText,
                    selectedTags: newRunDetails.selectedTags
                }
            };

            return { ...state, uiFilters: { ...uiFilters } };
        }

        case ADD_TO_ROUTER_HISTORY: {
            const { url } = action.payload;
            const currentHistory = state.routerHistory;
            const lastUrl = currentHistory[currentHistory.length - 1];
            if (lastUrl === url) {
                return state;
            }
            const newHistory = [...currentHistory, url];
            if (newHistory.length > 5) {
                newHistory.shift();
            }
            return { ...state, routerHistory: newHistory };
        }

        case POP_ROUTER_HISTORY: {
            const currentHistory = state.routerHistory;
            const newHistory = [...currentHistory];
            newHistory.pop();
            return { ...state, routerHistory: newHistory };
        }

        case SET_PLAN_REPORTS_LIST_ACTION: {
            const { planReportList } = action.payload;
            const newReports = { ...state.reports, plans: planReportList };
            const { reportListByPlan, reportListByTest, executions } = convertReportToReportHistory(newReports);
            return { ...state, reports: newReports, reportHistory: { reportListByPlan, reportListByTest }, executions };
        }
        case SET_PLAN_REPORT_ACTION: {
            const { planReport } = action.payload;
            const reportId = planReport.id;
            const newPlanReportList = [
                ...state.reports.plans.filter((planReport) => planReport.id !== reportId),
                planReport
            ];
            const newReports = { ...state.reports, plans: newPlanReportList };
            const { reportListByPlan, reportListByTest, executions } = convertReportToReportHistory(newReports);
            return { ...state, reports: newReports, reportHistory: { reportListByPlan, reportListByTest }, executions };
        }
        case SET_TEST_PLAN_REPORTS_LIST_ACTION: {
            const { planReportList } = action.payload;
            const newReports = { ...state.reports, tests: planReportList };
            const { reportListByPlan, reportListByTest, executions } = convertReportToReportHistory(newReports);
            return { ...state, reports: newReports, reportHistory: { reportListByPlan, reportListByTest }, executions };
        }
        case SET_TEST_PLAN_REPORT_ACTION: {
            const { planReport } = action.payload;
            const reportId = planReport.id;
            const newPlanReportList = [
                ...state.reports.tests.filter((planReport) => planReport.id !== reportId),
                planReport
            ];
            const newReports = { ...state.reports, tests: newPlanReportList };
            const { reportListByPlan, reportListByTest, executions } = convertReportToReportHistory(newReports);
            return { ...state, reports: newReports, reportHistory: { reportListByPlan, reportListByTest }, executions };
        }

        case SET_ABORT_PLAN_RESULTS: {
            const { data } = action.payload;
            const { executionId, reportId, planId, type, testId = null } = data;
            const executions = state.executions;
            const newAbortMap = { ...executions.inAbortedExecutions };
            const inAbortData = { id: planId, type: type, reportId, executionId, testId };
            let key = planId;
            if (type === 'test') {
                key = testId;
            } else {
                key = planId;
            }
            // any existing abort data exists?
            if (newAbortMap[key]) {
                newAbortMap[key].push(inAbortData);
            } else {
                newAbortMap[key] = [inAbortData];
            }
            // const newInAbortMap = { ...newAbortMap, [key]: inAbortData };
            const newInProgressExecution = { ...state.executions.inProgressExecutions };
            const newInQueueExecutions = { ...state.executions.inQueueExecutions };
            // also ensure it exists only on one of the execution lists
            if (newInProgressExecution[key]) {
                // should it iterate over the array and remove the execution?
                delete newInProgressExecution[key];
            }
            if (newInQueueExecutions[key]) {
                // should it iterate over the array and remove the execution?
                delete newInQueueExecutions[key];
            }
            const newExecutions = {
                inProgressExecutions: newInProgressExecution,
                inQueueExecutions: newInQueueExecutions,
                inAbortedExecutions: newAbortMap
            };
            return { ...state, executions: newExecutions };
        }

        case COPY_PLAN_ACTION: {
            const { workspaceId, currentPlanId } = action.payload;
            const currentPlanData = state.planList[currentPlanId];
            let currentPlanName = currentPlanData.name;
            let newPlanName = `Copy of ${currentPlanName}`;
            // make sure schedules are not copied
            const newPlanData = { ...currentPlanData, name: newPlanName, id: randomString(), schedules: [] };
            return {
                ...state,
                inMemoryPlanList: { [newPlanData.id]: newPlanData },
                goto: `/app/${workspaceId}/plans/edit/${newPlanData.id}`
            };
        }

        case COPY_TEST_ACTION: {
            const { workspaceId, currentTestId } = action.payload;
            const currentTestData = state.tests[currentTestId];
            let currentTestName = currentTestData.name;
            let newTestName = `Copy of ${currentTestName}`;
            const newTestData = { ...currentTestData, name: newTestName, id: randomString() };
            return {
                ...state,
                inMemoryTestList: { [newTestData.id]: newTestData },
                goto: `/app/${workspaceId}/editorV2/${newTestData.id}`
            };
        }

        case SET_GLOBAL_VARIABLE_LIST: {
            const { globalVariableList } = action.payload;
            return { ...state, globalVariableList };
        }

        case SET_GLOBAL_FILE_LIST: {
            const { globalFileList } = action.payload;
            return { ...state, globalFileList };
        }

        default:
            return state;
    }
};

function convertReportToReportHistory(report) {
    const { tests, plans } = report;
    const testToPlanReportMap = {};
    const inProgressExecutions = {};
    const inQueueExecutions = {};
    const counter = {};
    tests.forEach((testReport) => {
        const { groupList, runStatus, id } = testReport;

        if (counter[runStatus]) {
            counter[runStatus]++;
        } else {
            counter[runStatus] = 1;
        }
        if (groupList && groupList.length === 1) {
            const firstGroup = groupList[0];
            const { testList } = firstGroup;
            if (testList && testList.length === 1) {
                const firstTest = testList[0];
                const testId = firstTest.id;
                if (!TERMINAL_STATUS[runStatus]) {
                    const data = { id: testId, type: 'test', reportId: testReport.id, executionId: testReport.id };
                    if (runStatus === 'running') {
                        if (inProgressExecutions[testId]) {
                            inProgressExecutions[testId].push(data);
                        } else {
                            inProgressExecutions[testId] = [data];
                        }
                    }
                    if (runStatus === 'new') {
                        if (inQueueExecutions[testId]) {
                            inQueueExecutions[testId].push(data);
                        } else {
                            inQueueExecutions[testId] = [data];
                        }
                    }
                }
                if (testToPlanReportMap[testId]) {
                    testToPlanReportMap[testId].push(testReport);
                } else {
                    testToPlanReportMap[testId] = [testReport];
                }
            }
        }
    });

    Object.keys(testToPlanReportMap).forEach((key) => {
        const list = testToPlanReportMap[key];
        const sortedList = list.sort((a, b) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        });
        testToPlanReportMap[key] = sortedList;
    });

    const planToPlanReportMap = {};
    plans.forEach((planReport) => {
        const { planId, runStatus } = planReport;

        if (counter[runStatus]) {
            counter[runStatus]++;
        } else {
            counter[runStatus] = 1;
        }
        if (planId) {
            if (!TERMINAL_STATUS[runStatus]) {
                const data = { id: planId, type: 'plan', reportId: planReport.id, executionId: planReport.id };
                if (runStatus === 'running') {
                    if (inProgressExecutions[planId]) {
                        inProgressExecutions[planId].push(data);
                    } else {
                        inProgressExecutions[planId] = [data];
                    }
                }
                if (runStatus === 'new') {
                    if (inQueueExecutions[planId]) {
                        inQueueExecutions[planId].push(data);
                    } else {
                        inQueueExecutions[planId] = [data];
                    }
                }
            }
            if (planToPlanReportMap[planId]) {
                planToPlanReportMap[planId].push(planReport);
            } else {
                planToPlanReportMap[planId] = [planReport];
            }
        }
    });

    Object.keys(planToPlanReportMap).forEach((key) => {
        const list = planToPlanReportMap[key];
        const sortedList = list.sort((a, b) => {
            return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
        });
        planToPlanReportMap[key] = sortedList;
    });

    return {
        reportListByTest: { ...testToPlanReportMap },
        reportListByPlan: { ...planToPlanReportMap },
        executions: {
            inProgressExecutions,
            inQueueExecutions,
            inAbortedExecutions: {}
        }
    };
}

export default actionListeners;
