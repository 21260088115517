// convention: where the action is followed by what the action is. End the constant with _ACTION
export const HYDRATE = 'HYDRATE';
export const WAIT = 'WAIT';
export const GOTO = 'GOTO';
export const WAIT_DONE = 'WAIT_DONE';
export const GOTO_DONE = 'GOTO_DONE';
export const ADD_TO_ROUTER_HISTORY = 'ADD_TO_ROUTER_HISTORY';
export const POP_ROUTER_HISTORY = 'POP_ROUTER_HISTORY';
export const SIDEBAR_ITEM_SELECTION_ACTION = 'SIDEBAR_ITEM_SELECTION_ACTION';
export const SIDEBAR_TOGGLE_COLLAPSE_ACTION = 'SIDEBAR_TOGGLE_COLLAPSE_ACTION';
export const SET_WORKSPACE_ID_ACTION = 'SET_WORKSPACE_ID_ACTION';
export const SET_TESTFILE_ID_ACTION = 'SET_TESTFILE_ID_ACTION';
export const SET_PLAN_ID_ACTION = 'SET_PLAN_ID_ACTION';
export const SET_OPEN_MENUS_ACTION = 'SET_OPEN_MENUS_ACTION';
export const SET_TESTS_LIST_ACTION = 'SET_TESTS_LIST_ACTION';
export const SET_TAGS_LIST_ACTION = 'SET_TAGS_LIST_ACTION';
export const SET_PLANS_LIST_ACTION = 'SET_PLANS_LIST_ACTION';
export const SET_ENVS_LIST_ACTION = 'SET_ENVS_LIST_ACTION';
export const SET_SCHEDULES_LIST_ACTION = 'SET_SCHEDULES_LIST_ACTION';
export const UPDATE_TEST_DATA_IN_STATE = 'UPDATE_TEST_DATA_IN_STATE';
export const UPDATE_ENV_DATA_IN_STATE = 'UPDATE_ENV_DATA_IN_STATE';
export const UPDATE_SCHEDULE_DATA_IN_STATE = 'UPDATE_SCHEDULE_DATA_IN_STATE';
export const UPDATE_TAG_DATA_IN_STATE = 'UPDATE_TAG_DATA_IN_STATE';
export const UPDATE_PLAN_DATA_IN_STATE = 'UPDATE_PLAN_DATA_IN_STATE';
export const SET_EXECUTION_HISTORY_RESULTS = 'SET_EXECUTION_HISTORY_RESULTS';
export const UPDATE_API_TEST_STEP_DATA_ACTION = 'UPDATE_API_TEST_STEP_DATA_ACTION';
export const SET_TEST_EXECUTION_RESULT_ACTION = 'SET_TEST_EXECUTION_RESULT_ACTION';
export const SET_PENDING_EXECUTIONS_RESULTS = 'SET_PENDING_EXECUTIONS_RESULTS';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_USER_NOTIFICATION_DATA = 'SET_USER_NOTIFICATION_DATA';
export const SET_NOTIFICATION_AS_READ = 'SET_NOTIFICATION_AS_READ';
export const UPDATE_WAITLIST_DATA = 'UPDATE_WAITLIST_DATA';
export const ERROR_MESSAGE_DATA = 'ERROR_MESSAGE_DATA';
export const REMOVE_ERROR_MESSAGE_DATA = 'REMOVE_ERROR_MESSAGE_DATA';
export const UPDATE_TESTLIST_FILTER_IN_STATE = 'UPDATE_TESTLIST_FILTER_IN_STATE';
export const UPDATE_PLANLIST_FILTER_IN_STATE = 'UPDATE_PLANLIST_FILTER_IN_STATE';
export const UPDATE_RUNSUMMARY_FILTER_IN_STATE = 'UPDATE_RUNSUMMARY_FILTER_IN_STATE';
export const UPDATE_RUNDETAILS_FILTER_IN_STATE = 'UPDATE_RUNDETAILS_FILTER_IN_STATE';
export const SET_PLAN_REPORTS_LIST_ACTION = 'SET_ALL_PLAN_REPORTS_LIST_ACTION';
export const SET_PLAN_REPORT_ACTION = 'SET_PLAN_REPORT_ACTION';
export const SET_TEST_PLAN_REPORTS_LIST_ACTION = 'SET_TEST_PLAN_REPORTS_LIST_ACTION';
export const SET_TEST_PLAN_REPORT_ACTION = 'SET_TEST_PLAN_REPORT_ACTION';
export const SET_ABORT_PLAN_RESULTS = 'SET_ABORT_PLAN_RESULTS';
export const COPY_PLAN_ACTION = 'COPY_PLAN_ACTION';
export const COPY_TEST_ACTION = 'COPY_TEST_ACTION';
export const UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE = 'UPDATE_DEFAULT_SETTINGS_DATA_IN_STATE';
export const UPDATE_ALL_USERS_DATA_IN_STATE = 'UPDATE_ALL_USERS_DATA_IN_STATE';
export const SET_GLOBAL_VARIABLE_LIST = 'SET_GLOBAL_VARIABLE_LIST';
export const SET_GLOBAL_FILE_LIST = 'SET_GLOBAL_FILE_LIST';
