export const GA_TRACKING_ID = 'G-YJKC08351T';
import * as Fathom from 'fathom-client';
// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL, title?: string) => {
    Fathom.trackPageview();
    if (window?.gtag) {
        const trackObj = {
            page_path: url
        };
        if (title) {
            //@ts-ignore
            trackObj.page_title = title;
        }
        window.gtag('config', GA_TRACKING_ID, trackObj);
    }
};

type GTagEvent = {
    action: string;
    category: string;
    label: string;
    value: number;
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value = 0 }: GTagEvent) => {
    if (window?.gtag) {
        window.gtag('event', action, {
            event_category: category,
            event_label: label,
            value: value
        });
    }
    // Fathom.trackGoal(label, 0);
};

export const trackGoal = (event: string) => {
    const eventToCode = {
        signupSubmit: 'A5WHJLTN',
        forgotPassword: 'LRLRJ8XR',
        loginSubmit: 'J7M6LQB9',
        gotoFeedback: '6HNLDJIA',
        submitFeedback: 'KNH2ELVX',
        changePassword: '9D0JGMEY',
        gotoSignupCTA: 'LN3DFLTQ',
        gotoLoginCTAMobile: 'F6VHOAWR',
        gotoAppCTAMobile: 'SZUYYAZS',
        gotoLogin: '0J08XIH8',
        gotoSignup: 'MT2FITCX'
    };
    Fathom.trackGoal(eventToCode[event], 0);
};
