import { createStore, applyMiddleware } from 'redux';
import syncListeners from './sync/actionListeners';
import asyncListeners from './async/actionListeners';
// import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
/** next js specific starts- one time set up */
import { createWrapper } from 'next-redux-wrapper';
import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from './sync/actionTypes';

const bindMiddleware = (middleware) => {
    if (process.env.NODE_ENV !== 'production') {
        const { composeWithDevTools } = require('redux-devtools-extension');
        return composeWithDevTools(applyMiddleware(...middleware));
    }
    return applyMiddleware(...middleware);
};
export const makeStore = (context) => {
    const sagaMiddleware = createSagaMiddleware();
    const store = createStore(syncListeners, bindMiddleware([sagaMiddleware]));
    sagaMiddleware.run(asyncListeners);
    return store;
};
export const nextJSStore = createWrapper(makeStore, { debug: false });
/** next js specific ends */

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
