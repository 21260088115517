import firebaseClient from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import axios from 'axios';

// shaztra-development
export const CLIENT_CONFIG = {
    apiKey: 'AIzaSyDMB7enRH5OEorJ_6yhOo89oQKqvQ_izE8',
    authDomain: 'shaztra-development.firebaseapp.com',
    projectId: 'shaztra-development',
    storageBucket: 'shaztra-development.appspot.com',
    messagingSenderId: '819877698460',
    appId: '1:819877698460:web:2937fa8a8b41cd5a794659',
    measurementId: 'G-YJKC08351T'
};

if (!firebaseClient.apps.length) {
    firebaseClient.initializeApp(CLIENT_CONFIG);
    if (typeof window !== 'undefined') {
        (window as any).firebase = firebaseClient;
        firebaseClient.auth().setPersistence(firebaseClient.auth.Auth.Persistence.LOCAL);
    }
}
const app = firebaseClient.app();
const auth = firebaseClient.auth();
const db = firebaseClient.firestore();
const now = firebaseClient.firestore.Timestamp.now();
const storage = firebaseClient.storage();

export const signIn = async (email, password) => {
    try {
        const { user } = await auth.signInWithEmailAndPassword(email, password);
        return {
            id: user.uid,
            displayName: user.displayName,
            email: user.email
        };
    } catch (e) {
        console.log(e);
        const error = e;
        if (e.code === 'auth/user-not-found') {
            error.message = 'No account exists for this user. Maybe you signed up using a different e-mail address.';
        }
        return { error };
    }
};

export const signUp = async ({ firstName, lastName, role, roleDescription, organization, email, password, code }) => {
    try {
        const { user } = await auth.createUserWithEmailAndPassword(email, password);
        const token = await user.getIdToken();
        const { emailVerified, uid } = user;
        const userProfile = {
            displayName: `${firstName}`,
            firstName,
            lastName,
            initials: `${firstName[0]}${lastName[0]}`,
            role,
            roleDescription,
            organization,
            emailVerified,
            email,
            code
        };
        const postResponse = await axios.post('/auth/newUser', { userProfile, id: uid, token });

        await user.updateProfile(userProfile);
        await user.sendEmailVerification();
        return {
            userProfile
        };
    } catch (e) {
        let error = e;
        if (error.response) {
            e.response.data.message = e.response.data.error;
            error = e.response.data;
        }
        return { error };
    }
};

export const initiateNotificationListening = (userId: string, handler: (doc: any) => void) => {
    return db
        .collection('UserNotificationCollection')
        .doc(userId)
        .onSnapshot((snapshot) => {
            handler(snapshot);
        });
};

export const resetNotification = async (userId: string) => {
    return await db.collection('UserNotificationCollection').doc(userId).set({ messages: [] });
};

export const markAsReadNotification = async (userId: string, messageId: string) => {
    const notificationResultDocRef = db.collection('UserNotificationCollection').doc(userId);
    const doc = await notificationResultDocRef.get();
    const docData = doc.data();
    const newMessageList = docData.messages.map((msg) => {
        if (msg.id === messageId) {
            msg.isNew = false;
        }
        return msg;
    });
    // console.log(newMessageList);
    await notificationResultDocRef.update({
        messages: newMessageList
    });
};

export const getCurrentNotifications = async (userId: string) => {
    const doc = await db.collection('UserNotificationCollection').doc(userId).get();
    return doc.data();
};

export const verifyEmail = async (actionCode: string) => {
    try {
        await auth.applyActionCode(actionCode);
    } catch (e) {
        console.log('Unable to verify user!');
        throw e;
    }
};

export const resendVerificationEmail = async () => {
    const user = auth.currentUser;
    if (!user) {
        return { message: 'You need to log in first' };
    }
    if (user.emailVerified === true) {
        return { message: 'Your email has been verified' };
    }
    await user.sendEmailVerification();
    return { message: `Verification email sent to ${user.email}` };
};

export const forgotPassword = async (email: string) => {
    try {
        await auth.sendPasswordResetEmail(email);
    } catch (e) {
        return { error: 'Unable to send reset password email.' };
    }
    return { message: 'Email sent.' };
};

export const verifyChangeOrForgotPassword = async (actionCode: string, newPassword: string) => {
    try {
        await auth.verifyPasswordResetCode(actionCode);
    } catch (e) {
        return { error: 'Unable to change your password, you are using an incorrect link.' };
    }

    try {
        await auth.confirmPasswordReset(actionCode, newPassword);
    } catch (e) {
        return { error: 'Unable to change your password.' };
    }

    return { message: 'Successfully changed your password.' };
};

export const setOverviewState = async (newState) => {
    const user = auth.currentUser;
    const userId = user.uid;
    await db.collection('UserOverviewCollection').doc(userId).set(newState);
};

export const getOverviewState = async () => {
    const user = auth.currentUser;
    const userId = user.uid;
    const doc = await db.collection('UserOverviewCollection').doc(userId).get();
    const docData = doc.data();
    return docData;
};

export { firebaseClient, auth, db, storage, now, app };
