import { RootState } from './sync/actionTypes';
import { TERMINAL_STATUS } from './sync/runStatusUtil';

export const getSidebarOptions = (state: RootState) => {
    return state.uiState.sidebar;
};

export const getUIState = (state: RootState) => {
    return state.uiState;
};

export const getFilterState = (state: RootState) => {
    return state.uiFilters;
};

export const getTestListFilterState = (state: RootState) => {
    return state.uiFilters.testList;
};

export const getPlanListFilterState = (state: RootState) => {
    return state.uiFilters.planList;
};

export const getRunSummaryFilterState = (state: RootState) => {
    return state.uiFilters.runSummary;
};

export const getRunDetailsFilterState = (state: RootState) => {
    return state.uiFilters.runDetails;
};

export const getShowSpinner = (state: RootState) => {
    return state.wait;
};

export const getAllTestDataFromState = (state: RootState) => {
    return state.tests;
};

export const getTestDataFromState = (testId: string) => {
    return function (state: RootState) {
        return state.tests[testId] || state.inMemoryTestList[testId];
    };
};

export const getAllEnvDataFromState = (state: RootState) => {
    return state.envList;
};

export const getAllScheduleDataFromState = (state: RootState) => {
    return state.scheduleList;
};

export const getAllTagDataFromState = (state: RootState) => {
    return state.tagList;
};

export const getAllPlanDataFromState = (state: RootState) => {
    return state.planList;
};

export const getPlanDataFromState = (planId: string) => {
    return function (state: RootState) {
        return state.planList[planId] || state.inMemoryPlanList[planId];
    };
};

export const getDefaultSettingsFromState = () => {
    return function (state: RootState) {
        return state.settings.default || {};
    };
};

export const isPlanFromMemoryPlanList = (planId: string) => {
    return function (state: RootState) {
        if (state.inMemoryPlanList[planId]) {
            return true;
        }
        return false;
    };
};

export const isTestFromMemoryTestList = (testId: string) => {
    return function (state: RootState) {
        if (state.inMemoryTestList[testId]) {
            return true;
        }
        return false;
    };
};

export const getWorkspaceId = (state: RootState) => {
    return state.workspaceId;
};

export const getTestFileId = (state: RootState) => {
    return state.testFileId;
};

export const getPlanId = (state: RootState) => {
    return state.planId;
};

// export const getEnvId = (state: RootState) => {
//     return state.envnId;
// };

// export const getScheduleId = (state: RootState) => {
//     return state.scheduleId;
// };

export const getCurrentTestId = (state: RootState) => {
    return state.currentTestId;
};

// ---- New Reporting related --------

export const getAllReportHistory = (state: RootState) => {
    return { ...state.reportHistory.reportListByTest, ...state.reportHistory.reportListByPlan };
};

function createHistoryMap(list, type, allReports) {
    const historyMap = {};

    Object.keys(list).forEach((key) => {
        const history = list[key];
        const runPattern = [];
        const lastFiveExecutionIds = [];
        let lastExecutionDetails = null;
        if (history.length > 0) {
            lastExecutionDetails = history[0];
            for (let i = 0; i < history.length && i < 5; i++) {
                runPattern.push(history[i].runStatus);
                lastFiveExecutionIds.push(history[i].id);
            }

            // name, description, tags
            const planOrTestDoc = allReports[key];
            const { name, description, tags } = planOrTestDoc || { name: '', description: '', tags: [] };
            let lastRunResult = '...';
            if (lastExecutionDetails) {
                if (type === 'test') {
                    const { runStatus } = lastExecutionDetails;
                    const testDetails = lastExecutionDetails.groupList[0].testList[0];
                    const { numberOfStepsPassed, totalNumberOfSteps = testDetails.stepList.length } = testDetails;
                    if (TERMINAL_STATUS[runStatus]) {
                        lastRunResult = `${numberOfStepsPassed}/${totalNumberOfSteps} steps passed`;
                    }
                }
                if (type === 'plan') {
                    const { runStatus, numberOfTestsPassed, totalNumberOfTests } = lastExecutionDetails;
                    // const testDetails = lastExecutionDetails.groupList[0].testList[0];
                    if (TERMINAL_STATUS[runStatus]) {
                        lastRunResult = `${numberOfTestsPassed}/${totalNumberOfTests} tests passed`;
                    }
                }
            }

            historyMap[key] = {
                runPattern,
                lastFiveExecutionIds,
                lastExecutionDetails,
                reportList: list,
                type,
                name,
                description,
                tags,
                lastRunResult
            };
        }
    });
    return historyMap;
}

export const getTestReportHistoryMap = (state: RootState) => {
    const list = state.reportHistory.reportListByTest;
    const allItems = state.tests;
    return createHistoryMap(list, 'test', allItems);
};

export const getPlanReportHistoryMap = (state: RootState) => {
    const list = state.reportHistory.reportListByPlan;
    const allItems = state.planList;
    return createHistoryMap(list, 'plan', allItems);
};

export const getAllReportDataFromState = (state: RootState) => {
    const { plans, tests } = state.reports;
    return [...plans, ...tests];
};

function createReportDisplayData(list, type) {
    const reportDisplayData = [];
    list.forEach((item) => {
        let { id: reportId, updatedAt, groupList, name, description, runStatus, planId: itemId } = item;
        const key = reportId;
        let lastRunResult = '...';
        if (type === 'test') {
            const testDetails = item.groupList[0].testList[0];
            if (testDetails) {
                const { numberOfStepsPassed, totalNumberOfSteps = testDetails.stepList.length } = testDetails;
                itemId = testDetails.id;
                if (runStatus && TERMINAL_STATUS[runStatus]) {
                    lastRunResult = `${numberOfStepsPassed}/${totalNumberOfSteps} steps passed`;
                }
            }
        }
        if (type === 'plan') {
            const { runStatus, numberOfTestsPassed, totalNumberOfTests } = item;
            // const testDetails = lastExecutionDetails.groupList[0].testList[0];
            if (runStatus && TERMINAL_STATUS[runStatus]) {
                lastRunResult = `${numberOfTestsPassed}/${totalNumberOfTests} tests passed`;
            }
        }

        reportDisplayData.push({
            type,
            key,
            reportId,
            lastRunDate: updatedAt,
            groupList,
            name,
            description,
            runStatus,
            lastRunResult,
            itemId
        });
    });
    return reportDisplayData;
}

export const getDisplayReportDataFromState = (state: RootState) => {
    const { plans, tests } = state.reports;

    const planDisplayData = createReportDisplayData(plans, 'plan');
    const testDisplayData = createReportDisplayData(tests, 'test');

    const sortedData = [...planDisplayData, ...testDisplayData].sort((a, b) => {
        return new Date(b.lastRunDate).getTime() - new Date(a.lastRunDate).getTime();
    });

    return sortedData;
};

export const getExecutionList = (state: RootState) => {
    return state.executionHistory.executionList;
};

export const getUserData = (state: RootState) => {
    return state.userData;
};

export const getAuthToken = (state: RootState) => {
    return state.userData.authToken;
};

export const getInQueueList = (state: RootState) => {
    // return state.executions.pending;
    return state.executions.inQueueExecutions;
};

export const getInProgressList = (state: RootState) => {
    // return state.executions.inProgress;
    return state.executions.inProgressExecutions;
};

export const getInAbortedList = (state: RootState) => {
    // return state.executions.pending;
    return state.executions.inAbortedExecutions;
};

export const getUserNotificationData = (state: RootState) => {
    return state.userNotificationData;
};

export const getWaitListData = (state: RootState) => {
    return state.waitlistData;
};

export const getErrorMessage = (state: RootState) => {
    return state.error;
};

// export const getTotalExecutionCount = (state: RootState) => {
//     return state.executions.totalCount;
// };

// export const getCompletedExecutionCount = (state: RootState) => {
//     return state.executions.completedCount;
// };

export const getPendingExecutionCount = (state: RootState) => {
    // return state.executions.pending.length + state.executions.inProgress.length;
    return state.executions.inProgressExecutions.length;
};

export const getTotalTestCount = (state: RootState) => {
    return Object.keys(state.tests).length;
};

export const getMaxExecutionCount = (state: RootState) => {
    return state.userData?.settings.maxExecutions || 5;
};

export const getGotoPath = (state: RootState) => {
    return state.goto;
};

export const hasEditorAsLastRoute = (state: RootState) => {
    const history = state.routerHistory;
    const slicedArray = history.slice(Math.max(history.length - 2, 1));
    try {
        const filteredList = slicedArray.filter((url) => url && url.includes('/editor'));
        if (filteredList.length > 0) {
            return true;
        }
        return false;
    } catch (e) {
        return false;
    }
};
