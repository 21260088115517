import 'styles/build-css/nprogress.css';
import 'styles/build-css/custom-antd.css';
import 'styles/build-css/custom-bulma.css';
import { nextJSStore, useTypedSelector } from 'src/ui/app/stateManagement/store';
import NProgress from 'nprogress';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as Fathom from 'fathom-client';
import {
    addToRouterHistory,
    gotoDoneAction,
    setUserDataAction,
    setWorkspaceId
} from 'src/ui/app/stateManagement/sync/actionCreators';
import {
    getAllTestsAction,
    getAllPlansAction,
    getAllTagsAction,
    getAllEnvsAction,
    getAllSchedulesAction,
    getDefaultSettingsAction
} from 'src/ui/app/stateManagement/async/actionCreators';
import { AuthProvider } from 'src/ui/common/Auth';
import * as gtag from 'src/ui/common/gtag';
// import tawkTo from 'tawkto-react';
import { getGotoPath } from 'src/ui/app/stateManagement/stateDataSelectors';
import authCheck from 'src/server/authUtils/authCheck';
import { setupUserAnalytics } from 'src/ui/app/analytics/heap';
// const tawkToPropertyId = '5fc3f883a1d54c18d8ee9c21';
export const getServerSideProps = (ctx) => authCheck(ctx);
/* Configure loading progress bar and google analytics */
function App({ Component, pageProps }) {
    const router = useRouter();
    const { userData, user } = pageProps;
    const workspaceId = router.query.workspaceId;
    if (user && user.email) {
        setupUserAnalytics(user, user.email, workspaceId as string);
    }
    const dispatch = useDispatch();
    dispatch(setUserDataAction(userData));

    // const { user } = useAuth();
    const pathGoto = useTypedSelector(getGotoPath);
    const startProgress = () => {
        NProgress.start();
    };

    // const selectRightMenuItem = (uiRoute, router) => {
    //     if (uiRoute === '/app/[workspaceId]/[testFileId]') {
    //         const testId = router.query.testFileId;
    //         dispatch(sidebarItemSelectionAction(testId as string));
    //     } else if (uiRoute === '/app/[workspaceId]/plans/[planId]') {
    //         const planId = router.query.planId;
    //         dispatch(sidebarItemSelectionAction(planId as string));
    //     } else if (uiRoute === '/app/[workspaceId]/plans/edit/[planId]') {
    //         const planId = router.query.planId;
    //         dispatch(sidebarItemSelectionAction(planId as string));
    //     } else if (uiRoute === '/app/[workspaceId]/plans/edit') {
    //         dispatch(sidebarItemSelectionAction('testPlan'));
    //     } else if (uiRoute === '/app/[workspaceId]/runHistory/summary') {
    //         dispatch(sidebarItemSelectionAction('runHistorySummary'));
    //     } else if (uiRoute === '/app/[workspaceId]/runHistory/detailed') {
    //         dispatch(sidebarItemSelectionAction('runHistoryDetailed'));
    //     } else if (uiRoute === '/app/[workspaceId]/') {
    //         dispatch(sidebarItemSelectionAction('overview'));
    //     } else if (uiRoute === '/app/[workspaceId]/testList') {
    //         dispatch(sidebarItemSelectionAction('testList'));
    //     }
    // };

    useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            Fathom.load('XAEQOFZC', {
                includedDomains: ['shaztra.ai']
            });
        }
    });

    useEffect(() => {
        if (pathGoto) {
            // console.log(`explicit goto path: ${pathGoto}`);
            // selectRightMenuItem(pathGoto, router);
            router.push(pathGoto);
            dispatch(gotoDoneAction());
        }
    }, [pathGoto]);

    const endProgress = (url: URL) => {
        NProgress.done();
        gtag.pageview(url);
        if (url) {
            dispatch(addToRouterHistory((url as unknown) as string));
        }
    };

    useEffect(() => {
        router.events.on('routeChangeStart', startProgress);
        router.events.on('routeChangeComplete', endProgress);
        router.events.on('routeChangeError', endProgress);

        // If the component is unmounted, unsubscribe
        // from the event with the `off` method:
        return () => {
            router.events.off('routeChangeStart', startProgress);
            router.events.off('routeChangeComplete', endProgress);
            router.events.off('routeChangeError', endProgress);
        };
    }, [router]);

    useEffect(() => {
        if (workspaceId && userData && (userData.defaultWorkspaceId || userData.workspaceList)) {
            if (!userData.workspaceList) {
                if (userData.defaultWorkspaceId !== workspaceId) {
                    router.push(`/workspaceErr`);
                }
            } else {
                if (userData.workspaceList.indexOf(workspaceId) < 0) {
                    router.push(`/workspaceErr`);
                }
            }
        }

        if (workspaceId) {
            dispatch(setWorkspaceId(workspaceId as string));
            dispatch(getDefaultSettingsAction(workspaceId as string));
            dispatch(getAllTestsAction(workspaceId as string));
            dispatch(getAllPlansAction(workspaceId as string));
            dispatch(getAllTagsAction(workspaceId as string));
            dispatch(getAllEnvsAction(workspaceId as string));
            // dispatch(getExecutionHistoryAction(workspaceId as string));
            // dispatch(getPendingExecutionsAction(workspaceId as string));
            dispatch(getAllSchedulesAction(workspaceId as string));
            // dispatch(getAllTestPlanReportsAction(workspaceId as string));
            // dispatch(getAllPlanReportsAction(workspaceId as string));
        }
        // if (user && user.email && user.displayName) {
        //     //@ts-ignore
        //     window.Tawk_API = window.Tawk_API || {};
        //     //@ts-ignore
        //     window.Tawk_API.visitor = {
        //         name: user.displayName,
        //         email: user.email
        //     };
        // }
        if (user && user.email) {
            setupUserAnalytics(user, user.email, workspaceId as string);
        }
        // tawkTo(tawkToPropertyId);
    }, [dispatch, workspaceId, user]);

    const nextComponentProps = {
        ...pageProps,
        workspaceId
    };

    return (
        <>
            <Head>
                <title>Shaztra: The future of Automation Testing</title>
            </Head>
            <AuthProvider>
                <Component {...nextComponentProps} />
            </AuthProvider>
        </>
    );
}
export default nextJSStore.withRedux(App); /** next js specific starts- one time set up */
