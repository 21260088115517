// convention: REST operation, followed by intent. End the constant with _API
export const GET_ALL_TESTS_API = 'GET_ALL_TESTS_API';
export const GET_TEST_STEPS_API = 'GET_TEST_STEPS_API';
export const GET_TEST_API = 'GET_TEST_API';
export const RUN_TEST_API = 'RUN_TEST_API';
export const RUN_PLAN_API = 'RUN_PLAN_API';
export const ABORT_RUN_PLAN_API = 'ABORT_RUN_PLAN_API';
export const CREATE_TEST_API = 'CREATE_TEST_API';
export const UPDATE_TEST_API = 'UPDATE_TEST_API';
export const DELETE_TEST_API = 'DELETE_TEST_API';
export const GET_RUN_HISTORY_API = 'GET_RUN_HISTORY_API';
export const GET_PENDING_EXECUTIONS_API = 'GET_PENDING_EXECUTIONS_API';
export const SEND_FEEDBACK_API = 'SEND_FEEDBACK_API';
export const POST_JOIN_WAITLIST_API = 'POST_JOIN_WAITLIST_API';
export const GET_JOIN_WAITLIST_API = 'GET_JOIN_WAITLIST_API';
export const GET_ALL_ENVS_API = 'GET_ALL_ENVS_API';
export const GET_ENV_API = 'GET_ENV_API';
export const CREATE_ENV_API = 'CREATE_ENV_API';
export const UPDATE_ENV_API = 'UPDATE_ENV_API';
export const DELETE_ENV_API = 'DELETE_ENV_API';
export const GET_ALL_SCHEDULES_API = 'GET_ALL_SCHEDULES_API';
export const GET_SCHEDULE_API = 'GET_SCHEDULE_API';
export const CREATE_SCHEDULE_API = 'CREATE_SCHEDULE_API';
export const UPDATE_SCHEDULE_API = 'UPDATE_SCHEDULE_API';
export const DELETE_SCHEDULE_API = 'DELETE_SCHEDULE_API';
export const GET_ALL_TAGS_API = 'GET_ALL_TAGS_API';
export const GET_TAG_API = 'GET_TAG_API';
export const CREATE_TAG_API = 'CREATE_TAG_API';
export const UPDATE_TAG_API = 'UPDATE_TAG_API';
export const DELETE_TAG_API = 'DELETE_TAG_API';
export const GET_ALL_PLANS_API = 'GET_ALL_PLANS_API';
export const GET_PLAN_API = 'GET_PLAN_API';
export const CREATE_PLAN_API = 'CREATE_PLAN_API';
export const UPDATE_PLAN_API = 'UPDATE_PLAN_API';
export const DELETE_PLAN_API = 'DELETE_PLAN_API';
export const GET_ALL_TEST_PLAN_REPORTS_API = 'GET_ALL_TEST_PLAN_REPORTS_API';
export const GET_ALL_PLAN_REPORTS_API = 'GET_ALL_PLAN_REPORTS_API';
export const GET_TEST_PLAN_REPORT_API = 'GET_TEST_PLAN_REPORT_API';
export const GET_PLAN_REPORT_API = 'GET_PLAN_REPORT_API';
export const GET_DEFAULT_SETTINGS_API = 'GET_DEFAULT_SETTINGS_API';
export const UPDATE_DEFAULT_SETTINGS_API = 'UPDATE_DEFAULT_SETTINGS_API';
