// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
const VERCEL_ENV = process.env.VERCEL_ENV || 'none';
const URL = process.env.VERCEL_URL || 'local';
const SHA = process.env.VERCEL_GIT_COMMIT_SHA || 'sha-none';
const release = `shaztra-${URL}-${SHA}-${VERCEL_ENV}`;
Sentry.init({
    dsn: SENTRY_DSN || 'https://1286b864240e43e09fe7563d1d6ca41d@o1134822.ingest.sentry.io/6183100',
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.1,
    environment: VERCEL_ENV,
    release
    // ...
    // Note: if you want to override the automatic release value, do not set a
    // `release` value here - use the environment variable `SENTRY_RELEASE`, so
    // that it will also get attached to your source maps
});
